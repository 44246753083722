import axios from "./helpers/axios";
import { BitrixFile, Deal } from "./types/deal";
import { getContactById, getContactsByIds } from "./contact";
import { DealProduct } from "./types/product";
import { sleep } from "./helpers/timer";
import { getCompaniesByIds, getCompanyById } from "./company";
import { Status } from "./types/status";
import { QueryFilter } from "./helpers/apiHelper";
import { InvoiceStages } from "./types/invoice";

export const getDealProducts = (dealId: string): Promise<DealProduct[]> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.deal.productrows.get?id=${dealId}`
      )
      .then((result) => {
        resolve(result.data.result);
      })
      .catch((error) =>
        reject({
          message: `There was an error finding the products: ${
            error.response?.data?.error_description || error.message
          }`,
        })
      );
  });
};

export const getDeal = async (
  dealId: string,
  dealStages: Status[],
  invoiceStatuses: Status[]
): Promise<Deal> => {
  const deal = (
    await axios.get(
      `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.deal.get?ID=${dealId}`
    )
  ).data.result;

  return <Deal>{
    ...deal,
    STAGE: dealStages.find((s) => s.STATUS_ID === deal.STAGE_ID),
    CONTACT: await getContactById(deal.CONTACT_ID),
    COMPANY:
      deal.COMPANY_ID &&
      deal.COMPANY_ID !== "0" &&
      (await getCompanyById(deal.COMPANY_ID)),
    INVOICE_STATUS:
      deal.UF_CRM_1685573136 &&
      invoiceStatuses.find((s) => s.STATUS_ID === deal.UF_CRM_1685573136),
  };
};

export const getDealsForDeskTable = async (
  dealStages: Status[],
  invoiceStatuses: Status[],
  accountId: string,
  filtersAdded?: QueryFilter[]
): Promise<Deal[]> => {
  let next = 0;
  let dealsList: Deal[] = [];

  let filters = "";
  if (filtersAdded) {
    for (const filter of filtersAdded) {
      filters += `&FILTER[${filter.field}]=${filter.value}`;
    }
  }

  do {
    const queryResult = await axios.get(
      `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.deal.list?SELECT[0]=*&SELECT[1]=UF_*&FILTER[UF_CRM_PARNERUS_ACCOUNT_ID]=${accountId}${filters}&ORDER[ID]=DESC&start=${next}`
    );
    next = queryResult.data.next;
    dealsList = dealsList.concat(queryResult.data.result);
    await sleep(1500);
  } while (next);

  const contacts = await getContactsByIds(
    dealsList.reduce(
      (prev, deal) => (deal.CONTACT_ID ? [...prev, deal.CONTACT_ID] : prev),
      <string[]>[]
    ),
    accountId
  );
  const companies = await getCompaniesByIds(
    dealsList.reduce(
      (prev, deal) =>
        deal.COMPANY_ID && deal.COMPANY_ID !== "0"
          ? [...prev, deal.COMPANY_ID]
          : prev,
      <string[]>[]
    ),
    accountId
  );

  return dealsList.map((deal: Deal) => {
    return <Deal>{
      ...deal,
      STAGE: dealStages.find((s) => s.STATUS_ID === deal.STAGE_ID),
      CONTACT: contacts.find((c) => c.ID === deal.CONTACT_ID),
      COMPANY: companies.find((c) => c.ID === deal.COMPANY_ID),
      INVOICE_STATUS:
        deal.UF_CRM_1685573136 &&
        invoiceStatuses.find((s) => s.STATUS_ID === deal.UF_CRM_1685573136),
    };
  });
};

export const getDealsForDashboard = async (
  accountId: string
): Promise<Deal[]> => {
  let next = 0;
  let dealsList: Deal[] = [];

  do {
    const queryResult = await axios.get(
      `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.deal.list?SELECT[0]=*&SELECT[1]=UF_*&FILTER[UF_CRM_PARNERUS_ACCOUNT_ID]=${accountId}&ORDER[ID]=DESC&start=${next}`
    );
    next = queryResult.data.next;
    dealsList = dealsList.concat(queryResult.data.result);
    await sleep(1500);
  } while (next);

  return dealsList;
};

export const createDeal = (
  deal: Partial<Deal>,
  files: BitrixFile[],
  accountId: string,
  mediadorId: number,
  invoiceId?: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.deal.add`, {
        FIELDS: {
          ...deal,
          UF_CRM_PARNERUS_ACCOUNT_ID: accountId,
          UF_CRM_1684081911: files,
          UF_CRM_1691018773128: invoiceId,
          UF_CRM_1697571631: mediadorId,
          UF_CRM_1685573136: InvoiceStages.New,
        },
      })
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};

export const updateDealInvoiceStatus = (dealId: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.deal.update`, {
        ID: dealId,
        FIELDS: {
          UF_CRM_1685573136: InvoiceStages.Paid,
        },
      })
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};

export const updateDealDocuments = (
  dealId: string,
  files: BitrixFile[]
): Promise<string> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.deal.update`, {
        ID: dealId,
        FIELDS: {
          UF_CRM_1694637147899: files[0],
        },
      })
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};

export const setDealProducts = (
  dealId: string,
  productRows: Partial<DealProduct>[]
): Promise<string> => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.deal.productrows.set`,
        {
          id: dealId,
          rows: productRows,
        }
      )
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};
