import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useSharedContext } from "../../shared/SharedComponentsContext";

interface UpgradeModalProps {
  open: boolean;
  onClose: () => void;
}

export default function UpgradeModal({ open, onClose }: UpgradeModalProps) {
  const { upgradeModal } = useSharedContext();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Debe actualizar su plan para utilizar esta funcionalidad
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {upgradeModal.upgradeDescription}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
        <Button variant="contained" href="/dashboard/settings/?tab=subscription">
          Actualizar Plan
        </Button>
      </DialogActions>
    </Dialog>
  );
}
