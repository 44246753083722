import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { MenuItem, Stack } from "@mui/material";
// components
import Iconify from "../../../components/iconify";
import MenuPopover from "../../../components/menu-popover";
import { IconButtonAnimate } from "../../../components/animate";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function HelpPopover() {
  const navigate = useNavigate();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? "primary" : "default"}
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Iconify icon="ic:baseline-live-help" />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Stack sx={{ p: 1 }}>
          <MenuItem
            key={"Email"}
            onClick={() => {
              handleClosePopover();
              window.open("mailto:info@parnerus.com", "_blank");
            }}
          >
            <Iconify icon="ic:baseline-email" /> Email
          </MenuItem>
          <MenuItem
            key={"WhatsApp"}
            onClick={() => {
              handleClosePopover();
              window.open("https://wa.me/14079004604", "_blank");
            }}
          >
            <Iconify icon="ic:baseline-whatsapp" /> WhatsApp
          </MenuItem>
          <MenuItem
            key={"Pedir asesoramiento"}
            onClick={() => {
              // if (
              //   !user?.subscription?.plan ||
              //   user?.subscription?.plan !== "Parnerus"
              // ) {
              //   openUpgradeModal(
              //     `El asesoramiento gratuito por meet está disponible en el plan Parnerus. Si quieres continuar con la versión ${user?.subscription?.plan} puedes seguir disfrutando nuestras otras herramientas.`
              //   );
              //   return;
              // }
              handleClosePopover();
              window.open(
                "https://calendar.app.google/TqTQwj1vur5SPgwNA",
                "_blank"
              );
            }}
          >
            <Iconify icon="fluent:meet-now-16-filled" /> Pedir asesoramiento
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  );
}
