import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { Link, Typography } from "@mui/material";
// routes
import { PATH_AUTH } from "../../routes/paths";
// components
import Iconify from "../../components/iconify";
// sections
import AuthVerifyCodeForm from "../../sections/auth/AuthVerifyCodeForm";
// assets
import { EmailInboxIcon } from "../../assets/icons";
import { useAuthContext } from "../../auth/useAuthContext";
import axiosInstance from "../../utils/axios";
import { useEffect } from "react";
import LoginLayout from "../../layouts/login";

// ----------------------------------------------------------------------

export default function VerifyCodePage() {
  const { user, initialize, logout } = useAuthContext();
  const navigate = useNavigate();

  const generateCode = () => {
    axiosInstance.post("/api/account/generate-code");
  };

  useEffect(() => {
    initialize();
    setTimeout(() => {
      generateCode();
    }, 2000);
  }, []);

  return (
    <>
      <Helmet>
        <title> Verificar correo | Parnerus</title>
      </Helmet>

      {/* <EmailInboxIcon sx={{ mb: 5, height: 96 }} /> */}
      <LoginLayout title="Verificar correo">
        <Typography variant="h3" paragraph>
          Por favor, chequea tu correo!
        </Typography>

        <Typography sx={{ color: "text.secondary", mb: 5 }}>
          Hemos enviado un código de confirmación de 6 dígitos a tu correo{" "}
          {user?.email}, ingresa el código a continuación para verificar tu
          correo
        </Typography>

        <AuthVerifyCodeForm />

        <Typography variant="body2" sx={{ my: 3 }}>
          ¿No tienes un código? &nbsp;
          <Link
            variant="subtitle2"
            sx={{ cursor: "pointer" }}
            onClick={() => generateCode()}
          >
            Re-enviar un código
          </Link>
        </Typography>

        <Link
          onClick={() => {
            logout();
            navigate("/", { replace: true });
          }}
          color="inherit"
          variant="subtitle2"
          sx={{
            mx: "auto",
            alignItems: "center",
            display: "inline-flex",
            cursor: "pointer",
          }}
        >
          <Iconify icon="eva:chevron-left-fill" width={16} />
          Volver a iniciar sesión
        </Link>
      </LoginLayout>
    </>
  );
}
