import { Contact } from "../../bitrix/types/contact";
import { dbContact } from "../types/contact";

export const dbContactToBitrixMapper = (
  contact: dbContact,
  accountId: string
): Contact => {
  return {
    NAME: contact.NAME,
    LAST_NAME: contact.LAST_NAME,
    PHONE: contact.PHONE ? [{ VALUE_TYPE: "WORK", VALUE: contact.PHONE }] : [],
    EMAIL: contact.EMAIL ? [{ VALUE_TYPE: "WORK", VALUE: contact.EMAIL }] : [],
    UF_CRM_1683974997: contact.UF_STATUS,
    UF_CRM_1683975126: contact.UF_IRS_STATUS,
    UF_CRM_1684065883: contact.UF_OCUPATION,
    UF_CRM_1684066139: contact.UF_INTEREST,
    UF_CRM_1684080063: contact.UF_TYPE_OF_SERVICE,
    BIRTHDATE: contact.BIRTHDATE,
    UF_CRM_1688136804: contact.UF_ADDRESS,
    COMMENTS: contact.COMMENTS,
    UF_CRM_PARNERUS_ACCOUNT_ID: accountId,
  };
};
