import axios from "./helpers/axios";
import { CustomFields } from "./types/userFields";

export const getCustomListFields = async (entity: "lead" | "deal" | "contact" | "company"): Promise<CustomFields[]> => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.${entity}.userfield.list?FILTER[USER_TYPE_ID]=enumeration`)
      .then(result => {
        if (result.data.result.length) {
          resolve(result.data.result);
        } else {
          reject({
            message: "Custom fields not found"
          });
        }
      })
      .catch(error => reject({
        message: `There was an error finding the custom fields: ${error.response?.data?.error_description || error.message}`
      }));
  });
};
