// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("ic_user"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  invoice: icon("ic_invoice"),
  kanban: icon("ic_kanban"),
  external: icon("ic_external"),
  file: icon("ic_file"),
  folder: icon("ic_folder"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "menú",
    items: [
      { title: "Inicio", path: PATH_DASHBOARD.home, icon: ICONS.dashboard },
      // {
      //   title: "CRM",
      //   path: PATH_DASHBOARD.crm.root,
      //   icon: ICONS.analytics,
      //   children: [
      //     { title: "Negociaciones", path: PATH_DASHBOARD.crm.deals },
      //     { title: "Contactos", path: PATH_DASHBOARD.crm.clients },
      //     { title: "Facturas", path: PATH_DASHBOARD.crm.invoices },
      //   ],
      // },
      {
        title: "Contactos",
        path: PATH_DASHBOARD.crm.clients,
        icon: ICONS.file,
      },
      {
        title: "Empresas",
        path: PATH_DASHBOARD.crm.companies,
        icon: ICONS.ecommerce,
      },
      {
        title: "Cotizaciones",
        path: PATH_DASHBOARD.crm.quotes,
        icon: ICONS.folder,
      },
      {
        title: "Facturas",
        path: PATH_DASHBOARD.crm.invoices,
        icon: ICONS.invoice,
      },
      { title: "Ordenes", path: PATH_DASHBOARD.desk, icon: ICONS.kanban },
      { title: "Información", path: PATH_DASHBOARD.info, icon: ICONS.file },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "administración",
    items: [
      {
        title: "Configuración",
        path: PATH_DASHBOARD.settings,
        icon: ICONS.dashboard,
      },
      {
        title: "Soporte técnico",
        path: PATH_DASHBOARD.technicalSupport,
        icon: ICONS.external,
      },
      {
        title: "Soporte contable",
        path: PATH_DASHBOARD.accountingSupport,
        icon: ICONS.invoice,
      },
    ],
  },
];

export default navConfig;
