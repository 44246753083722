import { OrderProduct } from "../../../shared/OrderCreateSlider/OrderCreateSlider";
import { Deal } from "../../bitrix/types/deal";
import { DealProduct } from "../../bitrix/types/product";

export const proposedDealMapper = (
  contactId: string,
  companyId: string | undefined
): Partial<Deal> => {
  return {
    TITLE: "Parnerus order",
    CONTACT_ID: contactId,
    COMPANY_ID: companyId,
    UF_CRM_1685573136: "DT31_7:P" // Paid
  };
};

export const proposedDealProductMapper = (
  product: OrderProduct
): Partial<DealProduct> => {
  return {
    PRODUCT_ID: Number(product.id),
    PRICE: product.price,
    QUANTITY: product.quantity,
  };
};
