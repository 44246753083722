import { Helmet } from "react-helmet-async";
// @mui
import { Container, Typography } from "@mui/material";
// components
import { useSettingsContext } from "../../components/settings";
import { useEffect } from "react";

// ----------------------------------------------------------------------

export default function TechnicalSupportPage() {
  const { themeStretch } = useSettingsContext();

  useEffect(() => {
    const bitrixFormDiv = document.getElementById("bitrix-form");
    if (bitrixFormDiv && !bitrixFormDiv.innerText) {
      const script = document.createElement("script");
      script.setAttribute("data-b24-form", "inline/7/za50uw");
      script.setAttribute("data-skip-movin", "true");
      script.textContent = `(function(w,d,u){
        var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
        var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
        })(window,document,'https://cdn.bitrix24.com/b25128493/crm/form/loader_7.js');;`;

      bitrixFormDiv.appendChild(script);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Soporte Técnico | Parnerus</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Typography variant="h3" component="h1" paragraph>
          Soporte Técnico
        </Typography>

        <Typography gutterBottom>
          Complete el siguiente formulario para recibir soporte técnico.
        </Typography>

        <div
          id="bitrix-form"
          style={{ padding: "24px", marginTop: "32px" }}
        ></div>
      </Container>
    </>
  );
}
