import axiosInstance from "../../../utils/axios";
import { createCompany } from "../../bitrix/company";
import { createContact } from "../../bitrix/contact";
import { createDeal, setDealProducts } from "../../bitrix/deal";
import { dbCompanyToBitrixMapper } from "../mappers/companyMapper";
import { dbContactToBitrixMapper } from "../mappers/contactMapper";
import {
  proposedDealMapper,
  proposedDealProductMapper,
} from "../mappers/dealMapper";
import { ProposedOrder } from "../types/order";

export const createOrder = async (order: ProposedOrder): Promise<string> => {
  let bitrixCompanyId = order.company?.ID;
  let bitrixContactId = order.contact.ID;

  if (!bitrixCompanyId && order.company) {
    bitrixCompanyId = await createCompany(
      dbCompanyToBitrixMapper(order.company, order.accountId)
    );
    axiosInstance.post("/api/company/update-bitrix-id", {
      companyId: order.company.companyId,
      bitrixId: bitrixCompanyId,
    });
  }

  if (!bitrixContactId) {
    bitrixContactId = await createContact(
      dbContactToBitrixMapper(order.contact, order.accountId),
      bitrixCompanyId
    );
    axiosInstance.post("/api/contact/update-bitrix-id", {
      contactId: order.contact.contactId,
      bitrixId: bitrixContactId,
    });
  }

  const dealId = await createDeal(
    proposedDealMapper(bitrixContactId, bitrixCompanyId),
    order.files,
    order.accountId,
    order.mediadorId,
    order.invoiceId
  );

  // Put current Bitrix product pricing
  const updatedProducts = order.products.map((product) => ({
    ...product,
    price: order.productsList.find((p) => p.ID === product.id)
      ? Number(order.productsList.find((p) => p.ID === product.id)?.PRICE)
      : 0,
    total: order.productsList.find((p) => p.ID === product.id)
      ? Number(order.productsList.find((p) => p.ID === product.id)?.PRICE) *
        product.quantity
      : 0,
  }));

  await setDealProducts(dealId, updatedProducts.map(proposedDealProductMapper));

  return dealId;
};
