import { Company } from "../../bitrix/types/company";
import { dbCompany } from "../types/company";

export const dbCompanyToBitrixMapper = (
  company: dbCompany,
  accountId: string
): Company => {
  return {
    TITLE: company.TITLE,
    INDUSTRY: company.INDUSTRY,
    UF_CRM_1683977873: company.UF_STATUS,
    UF_CRM_1683978024: company.UF_IRS_STATUS,
    UF_CRM_1683982286: company.UF_STATE_OF_INCORPORATION,
    UF_CRM_1684078719: company.UF_TYPE_OF_SERVICE,
    UF_CRM_1684078500: company.UF_INCORPORATION_DATE,
    WEB: company.WEB ? [{ VALUE_TYPE: "WORK", VALUE: company.WEB }] : [],
    COMMENTS: company.COMMENTS,
    UF_CRM_PARNERUS_ACCOUNT_ID: accountId,
  };
};
