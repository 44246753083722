import { useState } from "react";
// @mui
import { Stack, Button, Tooltip, IconButton, Typography } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Iconify from "../../components/iconify";
import ConfirmDialog from "../../components/confirm-dialog";

// ----------------------------------------------------------------------

type Props = {
  fileInputRef: React.RefObject<HTMLInputElement>;
  liked: boolean;
  completed: boolean;
  onLike: VoidFunction;
  onAttach: VoidFunction;
  onCompleted: VoidFunction;
  onCloseDetails: VoidFunction;
};

export default function QuoteDetailsToolbar({
  fileInputRef,
  onCloseDetails,
}: Props) {
  const isDesktop = useResponsive("up", "sm");

  return (
    <>
      <Stack p={2.5} direction="row" alignItems="center">
        {!isDesktop && (
          <Tooltip title="Back">
            <IconButton onClick={onCloseDetails} sx={{ mr: 1 }}>
              <Iconify icon="eva:arrow-ios-back-fill" />
            </IconButton>
          </Tooltip>
        )}

        <Typography variant="overline">Editar Cotización</Typography>
      </Stack>

      <input ref={fileInputRef} type="file" style={{ display: "none" }} />
    </>
  );
}
