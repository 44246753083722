// @mui
import {
  Stack,
  InputAdornment,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
// components
import Iconify from "../../../../components/iconify";
import { InvoicesTableVariants } from "../../../../pages/CRM/Invoices/InvoicesTable";

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

type Props = {
  filterName: string;
  isFiltered: boolean;
  filterEndDate: Date | null;
  onResetFilter: VoidFunction;
  filterStartDate: Date | null;
  onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterStartDate: (value: Date | null) => void;
  onFilterEndDate: (value: Date | null) => void;
  variant: InvoicesTableVariants;
};

export default function InvoiceTableToolbar({
  filterName,
  isFiltered,
  onFilterName,
  filterEndDate,
  onResetFilter,
  filterStartDate,
  onFilterEndDate,
  onFilterStartDate,
  variant,
}: Props) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: "column",
        md: "row",
      }}
      sx={{ px: 2.5, py: variant === InvoicesTableVariants.Default ? 3 : 2 }}
    >
      <DatePicker
        label="Desde"
        value={filterStartDate}
        onChange={onFilterStartDate}
        renderInput={(params) => (
          <TextField
            {...params}
            size={
              variant === InvoicesTableVariants.Default ? "medium" : "small"
            }
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />

      <DatePicker
        label="Hasta"
        value={filterEndDate}
        onChange={onFilterEndDate}
        renderInput={(params) => (
          <TextField
            {...params}
            size={
              variant === InvoicesTableVariants.Default ? "medium" : "small"
            }
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />

      <TextField
        fullWidth
        size={variant === InvoicesTableVariants.Default ? "medium" : "small"}
        value={filterName}
        onChange={onFilterName}
        placeholder="Busca por el número de factura o cliente..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Clear
        </Button>
      )}
    </Stack>
  );
}
