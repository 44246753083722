import {
  Autocomplete,
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import Iconify from "../../components/iconify";
import { useBitrixContext } from "../../api/bitrix/context/BitrixContext";
import { QuoteProduct } from "./QuoteCreateSlider";
import { useState } from "react";

type QuoteProductsProps = {
  products: QuoteProduct[];
  onProductsChange: (products: QuoteProduct[]) => void;
};

export default function QuoteProducts({
  products,
  onProductsChange,
}: QuoteProductsProps) {
  const { productsList } = useBitrixContext();
  const [categoryFilter, setCategoryFilter] = useState<
    { value: string; index: number }[]
  >([
    { value: "All", index: 0 },
    { value: "All", index: 1 },
    { value: "All", index: 2 },
    { value: "All", index: 3 },
    { value: "All", index: 4 },
    { value: "All", index: 5 },
    { value: "All", index: 6 },
    { value: "All", index: 7 },
    { value: "All", index: 8 },
    { value: "All", index: 9 },
    { value: "All", index: 10 },
  ]);

  const handleQuantityChange = (
    event: { target: { name: string; value: string } },
    index: number
  ) => {
    onProductsChange(
      products.map((product, i) => {
        if (i === index) {
          return {
            ...product,
            [event.target.name]: event.target.value,
            total:
              event.target.value && product.price
                ? Number(event.target.value) * product.price
                : 0,
          };
        } else {
          return product;
        }
      })
    );
  };

  const handlePriceChange = (
    event: { target: { name: string; value: string } },
    index: number
  ) => {
    onProductsChange(
      products.map((product, i) => {
        if (i === index) {
          return {
            ...product,
            price: Number(event.target.value) || null,
            total: event.target.value
              ? Number(event.target.value) * product.quantity
              : 0,
          };
        } else {
          return product;
        }
      })
    );
  };

  const handleProductChange = (
    value: {
      label: string;
      id: string;
    } | null,
    index: number
  ) => {
    const productSelected = productsList.find((p) => p.ID === value?.id);
    onProductsChange(
      products.map((product, i) => {
        if (index === i) {
          return {
            ...product,
            id: value ? value.id : "",
            product: value ? value?.label : "",
            price: productSelected ? Number(productSelected.PRICE) : 0,
            total: productSelected
              ? Number(productSelected.PRICE) * product.quantity
              : 0,
          };
        } else {
          return product;
        }
      })
    );
  };

  const handleCategoryChange = (value: string, index: number) => {
    setCategoryFilter(
      categoryFilter.map((c) => {
        if (c.index === index) {
          return {
            ...c,
            value,
          };
        } else {
          return c;
        }
      })
    );
  };

  return (
    <Stack spacing={3} sx={{ py: 3, px: 2.5 }}>
      {products.map((item, index) => {
        let productsToShow = [];
        if (item.id) {
          productsToShow.push({
            id: item.id,
            label: item.product,
          });
        }
        const currentProductsIds = products.map((p) => p.id);
        const currentCategoryFilter = categoryFilter.find(
          (c) => c.index === index
        );
        productsToShow = [
          ...productsToShow,
          ...productsList
            .filter(
              (p) =>
                !currentProductsIds.includes(p.ID) &&
                (currentCategoryFilter?.value === "All" ||
                  currentCategoryFilter?.value === p.SECTION_ID)
            )
            .map((p) => ({
              label: p.NAME,
              id: p.ID,
            })),
        ];

        productsToShow.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        return (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              sx={{ width: 1 }}
            >
              <TextField
                select
                size="small"
                label="Categoría"
                InputLabelProps={{ shrink: true }}
                sx={{ width: { md: 120 } }}
                onChange={(e) => handleCategoryChange(e.target.value, index)}
                value={categoryFilter.find((c) => c.index === index)?.value}
              >
                <MenuItem key="1" value="All">
                  Todos
                </MenuItem>
                <MenuItem key="9" value="9">
                  Corporativos
                </MenuItem>
                <MenuItem key="11" value="11">
                  Contables
                </MenuItem>
                <MenuItem key="13" value="13">
                  Impositivos
                </MenuItem>
                <MenuItem key="15" value="15">
                  Otros
                </MenuItem>
              </TextField>

              <Autocomplete
                value={item.id ? { id: item.id, label: item.product } : null}
                size="small"
                disablePortal
                options={productsToShow}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ width: 250 }}
                onChange={(_, value) => handleProductChange(value, index)}
                renderInput={(params) => (
                  <TextField {...params} required label="Producto" />
                )}
              />

              <TextField
                size="small"
                type="number"
                name="quantity"
                label="Cantidad"
                value={item.quantity}
                onChange={(event) => handleQuantityChange(event, index)}
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 96 } }}
                required
              />

              <TextField
                helperText={
                  products[index].id &&
                  `Precio Parnerus $${
                    productsList.find((p) => p.ID === products[index].id)?.PRICE
                  }`
                }
                size="small"
                type="number"
                name={`items[${index}].price`}
                label="Precio"
                placeholder="0"
                value={item.price}
                onChange={(event) => handlePriceChange(event, index)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                sx={{ maxWidth: { md: 120 } }}
                required
              />

              <TextField
                disabled
                size="small"
                name={`items[${index}].total`}
                label="Total"
                placeholder="0"
                value={item.total}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                sx={{ maxWidth: { md: 120 } }}
              />
              {index !== 0 && (
                <Button
                  size="small"
                  color="error"
                  startIcon={<Iconify icon="eva:trash-2-outline" />}
                  onClick={() => {
                    const lineIndex = index;
                    onProductsChange(
                      products.filter((_, index) => index != lineIndex)
                    );
                  }}
                >
                  Borrar
                </Button>
              )}
            </Stack>
          </Stack>
        );
      })}
      <Divider sx={{ my: 3, borderStyle: "dashed" }} />
    </Stack>
  );
}
