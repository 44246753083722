import { useEffect, useState } from "react";
// @mui
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
// components
import Scrollbar from "../../../components/scrollbar";
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../../components/table";
// sections
import OrdersTableRow from "./OrdersTableRow";
import OrdersTableToolbar from "./OrdersTableToolbar";
import { Deal } from "../../../api/bitrix/types/deal";
import { getDealsForDeskTable } from "../../../api/bitrix/deal";
import { useBitrixContext } from "../../../api/bitrix/context/BitrixContext";
import { Status } from "../../../api/bitrix/types/status";
import { QueryFilter } from "../../../api/bitrix/helpers/apiHelper";
import { useAuthContext } from "../../../auth/useAuthContext";
import { useSharedContext } from "../../../shared/SharedComponentsContext";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "contact", label: "Contacto", align: "left" },
  { id: "opportunity", label: "Importe", align: "left" },
  { id: "stage", label: "Etapa", align: "left" },
  { id: "invoiceStatus", label: "Invoice Status", align: "left" },
  { id: "lastUpdate", label: "Última actualización", align: "left" },
  { id: "" },
];

export enum OrdersTableVariants {
  Default,
  Contact,
}

interface OrderTableProps {
  variant: OrdersTableVariants;
  filtersAdded?: QueryFilter[];
}

export default function OrdersTable({
  variant,
  filtersAdded,
}: OrderTableProps) {
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    setRowsPerPage,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  const { account } = useAuthContext();
  const { listFields } = useBitrixContext();
  const { loadingTrigger } = useSharedContext();
  const [isLoading, setIsLoading] = useState(true);

  const [tableData, setTableData] = useState<Deal[]>([]);

  const [filterGeneral, setFilterGeneral] = useState("");

  const [filterInvoiceStatus, setFilterInvoiceStatus] = useState("all");

  const [filterStatus, setFilterStatus] = useState("all");

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterGeneral,
    filterInvoiceStatus,
    filterStatus,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const isFiltered =
    filterGeneral !== "" ||
    filterInvoiceStatus !== "all" ||
    filterStatus !== "all";

  const isNotFound =
    (!dataFiltered.length && !!filterGeneral) ||
    (!dataFiltered.length && !!filterInvoiceStatus) ||
    (!dataFiltered.length && !!filterStatus);

  const handleFilterGeneral = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterGeneral(event.target.value);
  };

  const handleFilterInvoiceStatus = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
    setFilterInvoiceStatus(event.target.value);
  };

  const handleDeleteRow = (id: string) => {
    const deleteRow = tableData.filter((row: { ID: string }) => row.ID !== id);
    setSelected([]);
    setTableData(deleteRow);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }
  };

  const handleEditRow = (id: string) => {
    // navigate(PATH_DASHBOARD.user.edit(paramCase(id)));
  };

  const handleResetFilter = () => {
    setFilterGeneral("");
    setFilterInvoiceStatus("all");
    setFilterStatus("all");
  };

  useEffect(() => {
    setRowsPerPage(25);

    getDealsForDeskTable(
      listFields.find((f) => f.FIELD_NAME === "DEAL_STAGE")?.LIST as Status[],
      listFields.find((f) => f.FIELD_NAME === "SMART_INVOICE_STAGE_7")
        ?.LIST as Status[],
      account?.accountId,
      filtersAdded
    ).then((data) => {
      setTableData(data);
      setIsLoading(false);
    });
  }, [loadingTrigger]);

  return (
    <>
      <OrdersTableToolbar
        isFiltered={isFiltered}
        filterGeneral={filterGeneral}
        filterInvoiceStatus={filterInvoiceStatus}
        optionsInvoiceStatus={listFields.find(
          (lf) => lf.FIELD_NAME === "SMART_INVOICE_STAGE_7"
        )}
        onFilterGeneral={handleFilterGeneral}
        onFilterInvoiceStatus={handleFilterInvoiceStatus}
        onResetFilter={handleResetFilter}
        variant={variant}
      />

      <TableContainer sx={{ position: "relative", overflow: "unset" }}>
        <Scrollbar>
          <Table size="medium" sx={{ minWidth: 800 }}>
            <TableHeadCustom
              //order={order}
              //orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              //onSort={onSort}
            />

            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12}>Cargando información...</TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <OrdersTableRow
                      key={row.ID}
                      row={row}
                      selected={selected.includes(row.ID)}
                      onSelectRow={() => onSelectRow(row.ID)}
                      onDeleteRow={() => handleDeleteRow(row.ID)}
                      onEditRow={() => handleEditRow(row.ID)}
                    />
                  ))}

                <TableEmptyRows
                  emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                />

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            )}
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={dataFiltered.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </>
  );
}

function applyFilter({
  inputData,
  comparator,
  filterGeneral,
  filterInvoiceStatus,
}: {
  inputData: Deal[];
  comparator: (a: any, b: any) => number;
  filterGeneral: string;
  filterStatus: string;
  filterInvoiceStatus: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterGeneral) {
    inputData = inputData.filter((deal) => {
      return (
        deal.CONTACT?.NAME.toLowerCase().includes(
          filterGeneral.toLowerCase()
        ) ||
        (deal.CONTACT?.LAST_NAME &&
          deal.CONTACT?.LAST_NAME.toLowerCase().includes(
            filterGeneral.toLowerCase()
          )) ||
        deal.COMPANY?.TITLE.toLowerCase().includes(
          filterGeneral.toLowerCase()
        ) ||
        deal.TYPE?.NAME.toLowerCase().includes(filterGeneral.toLowerCase()) ||
        deal.INVOICE_STATUS?.NAME.toLowerCase().includes(
          filterGeneral.toLowerCase()
        )
      );
    });
  }

  // if (filterStatus !== "all") {
  //   inputData = inputData.filter((user) => user.status === filterStatus);
  // }

  if (filterInvoiceStatus !== "all") {
    inputData = inputData.filter(
      (deal) => deal.UF_CRM_1685573136 === filterInvoiceStatus
    );
  }

  return inputData;
}
