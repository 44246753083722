import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  AlertTitle,
  Stack,
} from "@mui/material";
import Scrollbar from "../../components/scrollbar";
import { DealProduct } from "../../api/bitrix/types/product";
import htmlParser from "html-react-parser";

interface OrderProductsRequisitesProps {
  open: boolean;
  onClose: VoidFunction;
  products: DealProduct[];
}

export default function OrderProductsRequisites({
  open,
  onClose,
  products,
}: OrderProductsRequisitesProps) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 2 }}>Documentación requerida</DialogTitle>

      <DialogContent>
        <Scrollbar sx={{ height: 300 }}>
          {products && products.length ? (
            <>
              {products.map((product) => {
                const descriptionElement = htmlParser(
                  product.PRODUCT_DESCRIPTION || ""
                );
                return (
                  <Stack
                    key={product.ID}
                    sx={{ width: "100%", mb: 2 }}
                    spacing={2}
                  >
                    <Alert severity="warning">
                      <AlertTitle>
                        Descripción: {product.PRODUCT_NAME}
                      </AlertTitle>
                      {descriptionElement}
                    </Alert>
                  </Stack>
                );
              })}
            </>
          ) : (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="info">
                Una vez seleccionados los productos para esta orden, se verán
                las descripciones y requerimientos necesarios en este espacio.
              </Alert>
            </Stack>
          )}
        </Scrollbar>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
