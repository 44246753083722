import { useState, useRef, FormEvent, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
  Stack,
  Drawer,
  Divider,
  TextField,
  Typography,
  Autocomplete,
  Button,
  MenuItem,
} from "@mui/material";
// components
import Iconify from "../../components/iconify";
import Scrollbar from "../../components/scrollbar";
//
import QuoteCreateToolbar from "./QuoteCreateToolbar";
import CreateContactModal from "../../pages/CRM/Contacts/CreateContact/CreateContactModal";
import QuoteFooter from "./QuoteCreateFooter";
import { useSharedContext } from "../SharedComponentsContext";
import CreateCompanytModal from "../../pages/CRM/Companies/CreateCompany/CreateCompanyModal";
import axiosInstance from "../../utils/axios";
import { createQuote } from "../../api/parnerus/services/quote";
import { dbContact } from "../../api/parnerus/types/contact";
import { dbCompany } from "../../api/parnerus/types/company";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useAuthContext } from "../../auth/useAuthContext";
import { dbQuote } from "../../api/parnerus/types/quote";

// ----------------------------------------------------------------------

const StyledLabel = styled("span")(({ theme }) => ({
  ...theme.typography.caption,
  width: 120,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

type Props = {
  isOpen: boolean;
  onClose?: VoidFunction;
};

export type QuoteProduct = {
  id: string;
  product: string;
  quantity: number;
  price: number | null;
  total: number;
};

interface FormProps {
  company: {
    id: string;
    label: string;
  } | null;
  contact: {
    id: string;
    label: string;
  } | null;
  dueDate: string;
  status: string;
  products: QuoteProduct[];
}

const formDefaults: FormProps = {
  company: null,
  contact: null,
  dueDate: "",
  status: "New",
  products: [
    {
      id: "",
      product: "",
      quantity: 1,
      price: 0,
      total: 0,
    },
  ],
};

export default function QuoteDetails({ isOpen, onClose }: Props) {
  const [formValues, setFormValues] = useState(formDefaults);
  const [onSubmit, setOnSubmit] = useState(false);

  const { account } = useAuthContext();
  const { closeQuoteCreateSlider, openQuoteViewSlider, triggerLoading } =
    useSharedContext();

  //Client Modals
  const [companyCreateOpen, setCompanyCreateOpen] = useState(false);
  const addCompany = (company: dbCompany) => {
    setCompanyList([...companyList, company]);
    setFormValues({
      ...formValues,
      company: {
        id: company.companyId,
        label: company.TITLE,
      },
    });
  };

  const [contactCreateOpen, setContactCreateOpen] = useState(false);
  const addContact = (contact: dbContact) => {
    setContactsList([...contactsList, contact]);
    setFormValues({
      ...formValues,
      contact: {
        id: contact.contactId as string,
        label: `${contact.NAME} ${contact.LAST_NAME}`,
      },
    });
  };

  // Company filtering
  const [companyList, setCompanyList] = useState<dbCompany[]>([]);
  const [companySearchingTimer, setCompanySearchingTimer] =
    useState<NodeJS.Timeout | null>(null);

  // Contact filtering
  const [contactsList, setContactsList] = useState<dbContact[]>([]);
  const [contactSearchingTimer, setContactSearchingTimer] =
    useState<NodeJS.Timeout | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [liked, setLiked] = useState(false);

  const [completed, setCompleted] = useState(true);

  const handleLiked = () => {
    setLiked(!liked);
  };

  const handleCompleted = () => {
    setCompleted(!completed);
  };

  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };

  const [isSearchingCompany, setIsSearchingCompany] = useState(false);

  const handleCompanySearchInput = (event: { target: { value: string } }, isFirstSearch: boolean) => {
    setIsSearchingCompany(true);
    const searchingValue = event.target.value.toLowerCase();
    if (companySearchingTimer) clearTimeout(companySearchingTimer);

    const newContactTimer = setTimeout(async () => {
      if (isFirstSearch || searchingValue.length > 2) {
        const searchingResults = await axiosInstance.get(
          `/api/company/list-by-title?TITLE=${searchingValue}&accountId=${account?.accountId}`
        );
        setCompanyList(searchingResults.data.results);
        setIsSearchingCompany(false);
      } else {
        setCompanyList([]);
        setIsSearchingCompany(false);
      }
    }, 1500);

    setCompanySearchingTimer(newContactTimer);
  };

  const [isSearchingContact, setIsSearchingContact] = useState(false);

  const handleContactSearchInput = (event: { target: { value: string } }, isFirstSearch: boolean) => {
    setIsSearchingContact(true);
    const searchingValue = event.target.value.toLowerCase();
    if (contactSearchingTimer) clearTimeout(contactSearchingTimer);

    const newContactTimer = setTimeout(async () => {
      if (isFirstSearch || searchingValue.length > 2) {
        const searchResults = await axiosInstance.get(
          `/api/contact/list-by-name?NAME=${searchingValue}&accountId=${account?.accountId}`
        );
        setContactsList(searchResults.data.results);
        setIsSearchingContact(false);
      } else {
        setContactsList([]);
        setIsSearchingContact(false);
      }
    }, 1500);

    setContactSearchingTimer(newContactTimer);
  };

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(formValues);
    setOnSubmit(true);

    const quote: dbQuote = await createQuote(
      contactsList.find(
        (c) => c.contactId === formValues.contact?.id
      ) as dbContact,
      companyList.find((c) => c.companyId === formValues.company?.id),
      formValues.products,
      formValues.status,
      formValues.dueDate,
      account?.accountId
    );

    setFormValues(formDefaults);
    if (onClose) {
      onClose();
    }
    closeQuoteCreateSlider();
    openQuoteViewSlider(quote);
    setOnSubmit(false);
    triggerLoading();
  };

  useEffect(() => {
    handleContactSearchInput({target: {value: ""}}, true);
    handleCompanySearchInput({target: {value: ""}}, true);
  }, []);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={closeQuoteCreateSlider}
        anchor="right"
        PaperProps={{
          sx: {
            width: {
              xs: 1,
              sm: 900,
            },
          },
        }}
      >
        <QuoteCreateToolbar
          fileInputRef={fileInputRef}
          liked={liked}
          completed={completed}
          onLike={handleLiked}
          onAttach={handleClickAttach}
          onCompleted={handleCompleted}
          onCloseDetails={closeQuoteCreateSlider}
        />

        <Divider />
        <form onSubmit={submitForm}>
          <Scrollbar>
            <Stack direction={{ xs: "column", sm: "row" }}>
              {/* LEFT PANEL--------------------------------------------------- */}
              <Stack spacing={3} sx={{ px: 2.5, pt: 3, pb: 5 }}>
                <Typography variant="overline">Cliente</Typography>

                <Stack direction="column">
                  <Stack direction="row" sx={{ gap: 2 }}>
                    <Autocomplete
                      loading={isSearchingContact}
                      value={formValues.contact}
                      disablePortal
                      options={contactsList.map((c) => ({
                        label: `${c.NAME} ${c.LAST_NAME}`,
                        id: c.contactId as string,
                      }))}
                      sx={{ width: 300 }}
                      onChange={(
                        _,
                        newValue: { label: string; id: string } | null
                      ) => {
                        setFormValues({
                          ...formValues,
                          contact: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Contacto"
                          onChange={(e) => {
                            handleContactSearchInput(e, false);
                          }}
                          required
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                    <Button
                      variant="text"
                      endIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={() => setContactCreateOpen(true)}
                    >
                      Nuevo
                    </Button>
                  </Stack>
                </Stack>

                <Stack direction="column">
                  <Stack direction="row" sx={{ gap: 2 }}>
                    <Autocomplete
                      loading={isSearchingCompany}
                      value={formValues.company}
                      disablePortal
                      options={companyList.map((c) => ({
                        label: c.TITLE,
                        id: c.companyId as string,
                      }))}
                      sx={{ width: 300 }}
                      onChange={(
                        event: any,
                        newValue: { label: string; id: string } | null
                      ) => {
                        setFormValues({
                          ...formValues,
                          company: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Empresa"
                          onChange={(e) => {
                            handleCompanySearchInput(e, false);
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                    <Button
                      variant="text"
                      endIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={() => setCompanyCreateOpen(true)}
                    >
                      Nuevo
                    </Button>
                  </Stack>
                </Stack>

                <Typography variant="overline">Detalles</Typography>

                <DesktopDatePicker
                  disablePast
                  label="Vencimiento"
                  value={
                    formValues.dueDate ? new Date(formValues.dueDate) : null
                  }
                  onChange={(newDate) => {
                    if (
                      newDate instanceof Date &&
                      !isNaN(newDate as unknown as number)
                    ) {
                      setFormValues({
                        ...formValues,
                        dueDate: newDate.toISOString(),
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} margin="normal" required />
                  )}
                />

                <TextField
                  select
                  label="Estado"
                  fullWidth
                  name="status"
                  value={formValues.status}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem key="New" value="New">
                    New
                  </MenuItem>
                  <MenuItem key="Sent to customer" value="Sent to customer">
                    Sent to customer
                  </MenuItem>
                  <MenuItem key="Accepted" value="Accepted">
                    Accepted
                  </MenuItem>
                  <MenuItem key="Declined" value="Declined">
                    Declined
                  </MenuItem>
                </TextField>
              </Stack>

              {/* RIGHT PANEL--------------------------------------------------- */}
              {/* <Stack spacing={3} sx={{ px: 2.5, pt: 3, pb: 5 }}>
                <Typography variant="overline">
                  Descripción y documentación requerida
                </Typography>

                {productValue && productValue.length ? (
                  <>
                    {productValue.map((productValue) => {
                      const productSelected = productsList.find(
                        (p) => p.ID === productValue.split("-")[0]
                      );
                      return (
                        <Stack
                          key={productValue}
                          sx={{ width: "100%" }}
                          spacing={2}
                        >
                          <Alert severity="warning">
                            <AlertTitle>
                              Descripción: {productSelected?.NAME}
                            </AlertTitle>
                            {productSelected?.DESCRIPTION}
                          </Alert>
                        </Stack>
                      );
                    })}
                  </>
                ) : (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="info">
                      Una vez seleccionados los productos para esta orden, se
                      verán las descripciones y requerimientos necesarios en
                      este espacio.
                    </Alert>
                  </Stack>
                )}
              </Stack> */}
            </Stack>

            <Divider />

            <QuoteFooter
              products={formValues.products}
              onProductsChange={(products) =>
                setFormValues({ ...formValues, products })
              }
              onSubmit={onSubmit}
            />
          </Scrollbar>
        </form>
      </Drawer>
      <CreateCompanytModal
        open={companyCreateOpen}
        onClose={() => setCompanyCreateOpen(false)}
        onAddCompany={addCompany}
      />

      <CreateContactModal
        open={contactCreateOpen}
        onClose={() => setContactCreateOpen(false)}
        onAddContact={addContact}
      />
    </>
  );
}
