// @mui
import {
  Stack,
  InputAdornment,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
// components
import Iconify from "../../../components/iconify";
import { ListValue } from "../../../api/bitrix/types/userFields";

// ----------------------------------------------------------------------

type Props = {
  filterName: string;
  filterStatus: string;
  isFiltered: boolean;
  optionsStatus: ListValue[] | undefined;
  onResetFilter: VoidFunction;
  onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterStatus: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function ContactsTableToolbar({
  isFiltered,
  filterName,
  filterStatus,
  optionsStatus,
  onFilterName,
  onFilterStatus,
  onResetFilter,
}: Props) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: "column",
        sm: "row",
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <TextField
        fullWidth
        select
        label="Status"
        value={filterStatus}
        onChange={onFilterStatus}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: 260,
              },
            },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: "capitalize",
        }}
      >
        {optionsStatus &&
          optionsStatus.map((option) => (
            <MenuItem
              key={option.ID}
              value={option.ID}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: "body2",
                textTransform: "capitalize",
              }}
            >
              {option.VALUE}
            </MenuItem>
          ))}
      </TextField>

      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterName}
        placeholder="Buscar..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Limpiar
        </Button>
      )}
    </Stack>
  );
}
