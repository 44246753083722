import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import axios from "../../../../utils/axios";
import { FormEvent, useState } from "react";
import { useBitrixContext } from "../../../../api/bitrix/context/BitrixContext";
import { dbContact } from "../../../../api/parnerus/types/contact";
import { useAuthContext } from "../../../../auth/useAuthContext";

interface Props extends DialogProps {
  onClose: VoidFunction;
  onAddContact?: (contact: dbContact) => void;
}

const formDefaults: Partial<dbContact> = {
  NAME: "",
  LAST_NAME: "",
  PHONE: "",
  EMAIL: "",
  UF_STATUS: "",
  UF_IRS_STATUS: "",
  UF_OCUPATION: [],
  UF_INTEREST: [],
  UF_TYPE_OF_SERVICE: [],
  BIRTHDATE: "",
  UF_ADDRESS: "",
  SOURCE_ID: "",
  UF_REFERRAL: "",
  COMMENTS: "",
};

export default function CreateContactModal({
  onClose,
  open,
  onAddContact,
}: Props) {
  const { account } = useAuthContext();
  const { userfieldList, listFields } = useBitrixContext();
  const [formValues, setFormValues] = useState(formDefaults);
  const [onSubmit, setOnSubmit] = useState(false);

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnSubmit(true);
    const response = await axios.post("/api/contact/insert", {
      ...formValues,
      accountId: account?.accountId,
    });

    if (onAddContact) {
      onAddContact(response.data.contact);
    }
    setFormValues(formDefaults);
    onClose();
    setOnSubmit(false);
  };

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle> Crear contacto </DialogTitle>

        <form onSubmit={submitForm}>
          <DialogContent sx={{ overflow: "unset" }}>
            <Typography
              variant="overline"
              sx={{ mb: 3, display: "block", color: "text.secondary" }}
            >
              Información general
            </Typography>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                name="NAME"
                onChange={handleInputChange}
                fullWidth
                label="Nombre"
                required
              />
              <TextField
                name="LAST_NAME"
                onChange={handleInputChange}
                fullWidth
                label="Apellido"
                required
              />
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                name="PHONE"
                onChange={handleInputChange}
                fullWidth
                label="Teléfono"
                type="phone"
              />
              <TextField
                name="EMAIL"
                onChange={handleInputChange}
                fullWidth
                label="Email"
                type="email"
              />
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="Status"
                fullWidth
                name="UF_STATUS"
                value={formValues.UF_STATUS}
                onChange={handleInputChange}
                required
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1683974997")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                select
                label="IRS Status"
                fullWidth
                name="UF_IRS_STATUS"
                value={formValues.UF_IRS_STATUS}
                onChange={handleInputChange}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1683975126")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>

            {/* <Typography
              variant="overline"
              sx={{ mb: 3, display: "block", color: "text.secondary" }}
            >
              Compañía
            </Typography>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField fullWidth label="Nombre de la compañía" />
            </Stack> */}

            <Typography
              variant="overline"
              sx={{ mb: 3, display: "block", color: "text.secondary" }}
            >
              Detalles
            </Typography>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="Ocupation"
                fullWidth
                name="UF_OCUPATION"
                value={formValues.UF_OCUPATION}
                onChange={handleInputChange}
                SelectProps={{
                  multiple: true,
                  renderValue: (value: unknown) =>
                    value
                      ? (value as string[])
                        .map(
                          (id) =>
                            userfieldList
                              .find(
                                (u) => u.FIELD_NAME === "UF_CRM_1684065883"
                              )
                              ?.LIST.find((v) => v.ID == id)?.VALUE
                        )
                        .join(", ")
                      : "",
                }}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1684065883")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      <Checkbox
                        checked={
                          formValues.UF_OCUPATION &&
                          formValues.UF_OCUPATION.indexOf(value.ID) > -1
                        }
                      />
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>

              <TextField
                select
                label="Interest"
                fullWidth
                name="UF_INTEREST"
                value={formValues.UF_INTEREST}
                onChange={handleInputChange}
                SelectProps={{
                  multiple: true,
                  renderValue: (value: unknown) =>
                    value
                      ? (value as string[])
                        .map(
                          (id) =>
                            userfieldList
                              .find(
                                (u) => u.FIELD_NAME === "UF_CRM_1684066139"
                              )
                              ?.LIST.find((v) => v.ID == id)?.VALUE
                        )
                        .join(", ")
                      : "",
                }}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1684066139")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      <Checkbox
                        checked={
                          formValues.UF_INTEREST &&
                          formValues.UF_INTEREST.indexOf(value.ID) > -1
                        }
                      />
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="Type of service"
                fullWidth
                name="UF_TYPE_OF_SERVICE"
                value={formValues.UF_TYPE_OF_SERVICE}
                onChange={(event) =>
                  handleInputChange({
                    ...event,
                    target: {
                      ...event.target,
                      value: [event.target.value],
                    },
                  })
                }
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1684080063")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>

              <DesktopDatePicker
                label="Fecha de nacimiento"
                value={
                  formValues.BIRTHDATE ? new Date(formValues.BIRTHDATE) : null
                }
                defaultCalendarMonth={new Date("2000-01-01")}
                onChange={(newDate) => {
                  if (
                    newDate instanceof Date &&
                    !isNaN(newDate as unknown as number)
                  ) {
                    setFormValues({
                      ...formValues,
                      BIRTHDATE: newDate.toISOString(),
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} margin="normal" />
                )}
              />
            </Stack>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                fullWidth
                label="Dirección"
                name="UF_ADDRESS"
                value={formValues.UF_ADDRESS}
                onChange={handleInputChange}
              />

              <TextField
                select
                label="Origen"
                fullWidth
                name="SOURCE_ID"
                value={formValues.SOURCE_ID}
                onChange={handleInputChange}
              >
                {listFields
                  .find((u) => u.FIELD_NAME === "SOURCE")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.STATUS_ID} value={value.STATUS_ID}>
                      {value.NAME}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>

            {formValues.SOURCE_ID && formValues.SOURCE_ID === "2" && (
              <Stack
                spacing={3}
                marginBottom={3}
                direction={{ xs: "column", sm: "row" }}
              >
                <TextField
                  fullWidth
                  label="Referral"
                  name="UF_REFERRAL"
                  value={formValues.UF_REFERRAL}
                  onChange={handleInputChange}
                />
              </Stack>
            )}

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                fullWidth
                label="Comentario"
                multiline
                rows={4}
                name="COMMENTS"
                value={formValues.COMMENTS}
                onChange={handleInputChange}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              color="inherit"
              variant="outlined"
              onClick={onClose}
              disabled={onSubmit}
            >
              Cancelar
            </Button>

            <Button variant="contained" type="submit" disabled={onSubmit}>
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
