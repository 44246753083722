import axios from "./helpers/axios";
import { Company } from "./types/company";

export const getCompanyById = (companyId: string): Promise<Company> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.company.get?ID=${companyId}`
      )
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};

export const getCompaniesByIds = (
  companyIds: string[],
  accountId: string
): Promise<Company[]> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.company.list`, {
        FILTER: {
          ID: companyIds,
          UF_CRM_PARNERUS_ACCOUNT_ID: accountId,
        },
      })
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};

export const createCompany = (company: Company): Promise<string> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.company.add`, {
        FIELDS: {
          ...company,
        },
      })
      .then((response) => resolve(response.data.result))
      .catch((error) => reject(error.message));
  });
};
