import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import {
  Page404,
  LoginPage,
  HomePage,
  DeskPage,
  ClientsPage,
  InvoicesPage,
  SettingsPage,
  InfoPage,
} from "./elements";
import OrderCreateSlider from "../shared/OrderCreateSlider/OrderCreateSlider";
import { useSharedContext } from "../shared/SharedComponentsContext";
import OrderViewSlider from "../shared/OrderViewSlider/OrderViewSlider";
import ContactViewSlider from "../shared/ContactViewSlider/ContactViewSlider";
import EditContactModal from "../pages/CRM/Contacts/EditContact/EditContactModal";
import CompaniesPage from "../pages/CRM/Companies/CompaniesPage";
import QuotesPage from "../pages/CRM/Quotes/QuotePage";
import CompanyViewSlider from "../shared/CompanyViewSlider/CompanyViewSlider";
import InvoiceCreateSlider from "../shared/InvoiceCreateSlider/InvoiceCreateSlider";
import InvoiceViewSlider from "../shared/InvoiceViewSlider/InvoiceViewSlider";
import InvoiceEditSlider from "../shared/InvoiceEditSlider/InvoiceEditSlider";
import QuoteCreateSlider from "../shared/QuoteCreateSlider/QuoteCreateSlider";
import QuoteViewSlider from "../shared/QuoteViewSlider/QuoteViewSlider";
import QuoteEditSlider from "../shared/QuoteEditSlider/QuoteEditSlider";
import RegisterPage from "../pages/auth/RegisterPage";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage";
import NewPasswordPage from "../pages/auth/NewPasswordPage";
import VerifyCodePage from "../pages/auth/VerifyCodePage";
import TechnicalSupportPage from "../pages/Support/TechnicalSupportPage";
import AccountingSupportPage from "../pages/Support/AccountingSupportPage";
import EditCompanyModal from "../pages/CRM/Companies/EditCompany/EditCompanyModal";
import PaymentModal from "../shared/PaymentModal/PaymentModal";
import UpgradeModal from "../components/upgrade-modal/UpgradeModal";
import OrderPaymentModal from "../shared/OrderPaymentModal/OrderPaymentModal";

// ----------------------------------------------------------------------

export default function Router() {
  const {
    orderCreateSliderOpen,
    closeOrderCreateSlider,
    orderViewSliderOpen,
    contactViewSliderOpen,
    contactEditSliderOpen,
    closeContactEditSlider,
    companyViewSliderOpen,
    invoiceCreateSliderOpen,
    invoiceViewSliderOpen,
    invoiceEditSliderOpen,
    quoteCreateSliderOpen,
    quoteViewSliderOpen,
    quoteEditSliderOpen,
    companyEditSliderOpen,
    closeCompanyEditSlider,
    paymentModalOpen,
    orderPaymentModalOpen,
    closePaymentModal,
    closeOrderPaymentModal,
    upgradeModal,
    closeUpgradeModal,
  } = useSharedContext();

  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },
            { path: "verify", element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
          <OrderCreateSlider
            isOpen={orderCreateSliderOpen}
            onClose={closeOrderCreateSlider}
          />
          <OrderViewSlider isOpen={orderViewSliderOpen} />
          <ContactViewSlider isOpen={contactViewSliderOpen} />
          <EditContactModal
            open={contactEditSliderOpen}
            onClose={closeContactEditSlider}
          />
          <CompanyViewSlider isOpen={companyViewSliderOpen} />
          <EditCompanyModal
            open={companyEditSliderOpen}
            onClose={closeCompanyEditSlider}
          />
          <InvoiceCreateSlider isOpen={invoiceCreateSliderOpen} />
          <InvoiceViewSlider isOpen={invoiceViewSliderOpen} />
          <InvoiceEditSlider isOpen={invoiceEditSliderOpen} />
          <QuoteCreateSlider isOpen={quoteCreateSliderOpen} />
          <QuoteViewSlider isOpen={quoteViewSliderOpen} />
          <QuoteEditSlider isOpen={quoteEditSliderOpen} />
          <PaymentModal open={paymentModalOpen} onClose={closePaymentModal} />
          <OrderPaymentModal
            open={orderPaymentModalOpen}
            onClose={closeOrderPaymentModal}
          />
          <UpgradeModal open={upgradeModal.open} onClose={closeUpgradeModal} />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "home", element: <HomePage /> },
        { path: "desk", element: <DeskPage /> },
        {
          path: "crm",
          children: [
            {
              element: <Navigate to="/dashboard/crm/deals" replace />,
              index: true,
            },
            { path: "clients", element: <ClientsPage /> },
            { path: "companies", element: <CompaniesPage /> },
            { path: "invoices", element: <InvoicesPage /> },
            { path: "quotes", element: <QuotesPage /> },
          ],
        },
        { path: "info", element: <InfoPage /> },
        { path: "settings", element: <SettingsPage /> },
        { path: "technical-support", element: <TechnicalSupportPage /> },
        { path: "accounting-support", element: <AccountingSupportPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
