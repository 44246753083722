import { useState, useRef, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
// components
import Scrollbar from "../../components/scrollbar";
//
import { useBitrixContext } from "../../api/bitrix/context/BitrixContext";
import ContactViewToolbar from "./ContactViewToolbar";
import { useSharedContext } from "../SharedComponentsContext";
import OrdersTable, { OrdersTableVariants } from "../../pages/Desk/OrdersTable";
import { format } from "date-fns";
import Label from "../../components/label";
import InvoicesTable, {
  InvoicesTableVariants,
} from "../../pages/CRM/Invoices/InvoicesTable";
import QuotesTable, {
  QuotesTableVariants,
} from "../../pages/CRM/Quotes/QuoteTable";

// ----------------------------------------------------------------------

const StyledLabel = styled("span")(({ theme }) => ({
  ...theme.typography.caption,
  width: 120,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`contact-tabpanel-${index}`}
      aria-labelledby={`contact-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type Props = {
  isOpen: boolean;
};

export default function OrderDetails({ isOpen }: Props) {
  const { closeContactViewSlider } = useSharedContext();
  const { contact } = useSharedContext();

  const [tabSelected, setTabSelected] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  };

  const { userfieldList, listFields } = useBitrixContext();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [liked, setLiked] = useState(false);

  const [completed, setCompleted] = useState(true);

  const handleLiked = () => {
    setLiked(!liked);
  };

  const handleCompleted = () => {
    setCompleted(!completed);
  };

  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    setTabSelected(0);
  }, [isOpen]);

  const status = userfieldList
    .find((u) => u.FIELD_NAME === "UF_CRM_1683974997")
    ?.LIST.find((v) => v.ID === contact?.UF_STATUS)?.VALUE;

  const irsStatus = userfieldList
    .find((u) => u.FIELD_NAME === "UF_CRM_1683975126")
    ?.LIST.find((v) => v.ID === contact?.UF_IRS_STATUS)?.VALUE;

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={closeContactViewSlider}
        anchor="right"
        PaperProps={{
          sx: {
            width: {
              xs: 1,
              sm: 900,
            },
          },
        }}
      >
        <ContactViewToolbar
          fileInputRef={fileInputRef}
          liked={liked}
          completed={completed}
          onLike={handleLiked}
          onAttach={handleClickAttach}
          onCompleted={handleCompleted}
          onCloseDetails={closeContactViewSlider}
        />

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ px: 2.5, pt: 1, pb: 5 }}>
            <Tabs
              value={tabSelected}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Información general" />
              <Tab label="Cotizaciones" />
              <Tab label="Facturas" />
              <Tab label="Órdenes" />
            </Tabs>

            <CustomTabPanel value={tabSelected} index={0}>
              <Stack spacing={3}>
                <Typography variant="overline">Información</Typography>

                <Stack direction="row" spacing={3}>
                  <Stack direction="column" width={100}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Nombre
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {contact?.NAME || "-"}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={180}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Apellido
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {contact?.LAST_NAME || "-"}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={180}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Teléfono
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {contact?.PHONE || "-"}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={180}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Email
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {contact?.EMAIL || "-"}
                    </Stack>
                  </Stack>
                </Stack>

                <Divider />

                <Typography variant="overline" sx={{ pt: 3 }}>
                  DETALLES
                </Typography>

                <Stack direction="row" spacing={3}>
                  <Stack direction="column" width={180}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Ocupation
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {contact?.UF_OCUPATION && contact?.UF_OCUPATION.length
                        ? contact.UF_OCUPATION.map(
                          (value) =>
                            userfieldList
                              .find(
                                (u) => u.FIELD_NAME === "UF_CRM_1684065883"
                              )
                              ?.LIST.find((v) => v.ID === value)?.VALUE
                        ).join(", ")
                        : "-"}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={180}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Interest
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {contact?.UF_INTEREST && contact?.UF_INTEREST.length
                        ? contact.UF_INTEREST.map(
                          (value) =>
                            userfieldList
                              .find(
                                (u) => u.FIELD_NAME === "UF_CRM_1684066139"
                              )
                              ?.LIST.find((v) => v.ID === value)?.VALUE
                        ).join(", ")
                        : "-"}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={180}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Type of service
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {contact?.UF_TYPE_OF_SERVICE && contact?.UF_TYPE_OF_SERVICE.length
                        ? contact.UF_TYPE_OF_SERVICE.map(
                          (value) =>
                            userfieldList
                              .find(
                                (u) => u.FIELD_NAME === "UF_CRM_1684080063"
                              )
                              ?.LIST.find((v) => v.ID === value)?.VALUE
                        ).join(", ")
                        : "-"}
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction="row" spacing={3}>
                  <Stack direction="column" width={180}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Fecha de nacimiento
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {contact?.BIRTHDATE
                        ? format(new Date(contact.BIRTHDATE), "dd MMM yyyy")
                        : "-"}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={180}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Dirección
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {contact?.UF_ADDRESS || "-"}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={200}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Origen
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {contact?.SOURCE_ID
                        ? listFields
                          .find((lf) => lf.FIELD_NAME === "SOURCE")
                          ?.LIST.find(
                            (v) => v.STATUS_ID === contact.SOURCE_ID
                          )?.NAME
                        : "-"}
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction="row" spacing={3} sx={{ pt: 2 }}>
                  <Stack direction="column" width={180}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Status
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {status ? (
                        <Label
                          variant="soft"
                          color={
                            (contact?.UF_STATUS === "4" && "error") || "success"
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          {status}
                        </Label>
                      ) : "-"}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={180}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      IRS Status
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {irsStatus ? (
                        <Label
                          variant="soft"
                          color={
                            (contact?.UF_IRS_STATUS === "51" && "error") ||
                            "success"
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          {irsStatus}
                        </Label>
                      ) : "-"}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={300}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Comentarios
                    </StyledLabel>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {contact?.COMMENTS || "-"}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </CustomTabPanel>

            <CustomTabPanel value={tabSelected} index={1}>
              <Stack spacing={3}>
                {contact && (
                  <QuotesTable
                    variant={QuotesTableVariants.ContactAndCompany}
                    filterAdded={{
                      field: "contactId",
                      value: contact.contactId as string,
                    }}
                  />
                )}
              </Stack>
            </CustomTabPanel>

            <CustomTabPanel value={tabSelected} index={2}>
              <Stack spacing={3}>
                {contact && (
                  <InvoicesTable
                    variant={InvoicesTableVariants.ContactAndCompany}
                    filterAdded={{
                      field: "contactId",
                      value: contact.contactId as string,
                    }}
                  />
                )}
              </Stack>
            </CustomTabPanel>

            <CustomTabPanel value={tabSelected} index={3}>
              <Stack spacing={3}>
                <OrdersTable
                  variant={OrdersTableVariants.Contact}
                  filtersAdded={[
                    { field: "CONTACT_ID", value: contact?.ID || "99999999" },
                  ]}
                />
              </Stack>
            </CustomTabPanel>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
