import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(
  lazy(() => import("../pages/auth/LoginPage"))
);
export const NewPasswordPage = Loadable(
  lazy(() => import("../pages/auth/NewPasswordPage"))
);
export const RegisterPage = Loadable(
  lazy(() => import("../pages/auth/RegisterPage"))
);
export const ResetPasswordPage = Loadable(
  lazy(() => import("../pages/auth/ResetPasswordPage"))
);
export const VerifyCodePage = Loadable(
  lazy(() => import("../pages/auth/VerifyCodePage"))
);
export const HomePage = Loadable(lazy(() => import("../pages/Home/HomePage")));
export const DeskPage = Loadable(lazy(() => import("../pages/Desk/DeskPage")));
export const ClientsPage = Loadable(
  lazy(() => import("../pages/CRM/Contacts/ContactsPage"))
);
export const CompaniesPage = Loadable(
  lazy(() => import("../pages/CRM/Companies/CompaniesPage"))
);
export const InvoicesPage = Loadable(
  lazy(() => import("../pages/CRM/Invoices/InvoicesPage"))
);
export const SettingsPage = Loadable(
  lazy(() => import("../pages/Settings/SettingsPage"))
);
export const TechnicalSupportPage = Loadable(
  lazy(() => import("../pages/Support/TechnicalSupportPage"))
);
export const AccountingSupportPage = Loadable(
  lazy(() => import("../pages/Support/AccountingSupportPage"))
);
export const InfoPage = Loadable(lazy(() => import("../pages/Info/InfoPage")));

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
