import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { format } from "date-fns";
import { AuthUserType } from "../../../auth/types";
import { dbContact } from "../../../api/parnerus/types/contact";
import { dbCompany } from "../../../api/parnerus/types/company";
import { dbInvoice } from "../../../api/parnerus/types/invoice";
import { dbAccount } from "../../../api/parnerus/types/account";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    fontSize: "10px",
    padding: 32,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  table: {
    padding: 24,
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid black",
    paddingTop: 8,
    paddingBottom: 4,
  },
  tableFooter: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  section: {
    margin: 10,
    padding: 10,
    gap: 2,
  },
  sectionRight: {
    margin: 10,
    padding: 10,
    justifySelf: "flex-end",
    gap: 2,
  },
  image: {
    // marginBottom: 10,
    width: 100,
  },
});

interface InvoiceDocumentParams {
  invoice: dbInvoice;
  user: AuthUserType;
  contact: dbContact | undefined;
  company: dbCompany | undefined;
  account: dbAccount;
}

// Create Document Component
export default (params: InvoiceDocumentParams) => {
  const { invoice, user, contact, company, account } = params;
  if (!user || !contact || !account) return <Document />;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <Header /> */}
        <View style={styles.row}>
          {account.accountLogo && (
            <View style={{ ...styles.section, marginTop: "-1px" }}>
              <Image src={account.accountLogo} style={styles.image} />
            </View>
          )}
          <View style={styles.section}>
            {account.taxId && <Text>Tax ID #: {account.taxId}</Text>}
            {account.phoneNumber && <Text>Tel.: {account.phoneNumber}</Text>}
            <Text>E-mail: {user.email}</Text>
          </View>
          <View style={styles.section}>
            <Text>
              Invoice #{invoice.invoiceId.split("-")[0].toUpperCase()}
            </Text>
            <Text>{user.displayName}</Text>
            <Text>{account.address}</Text>
            <Text>{account.city}</Text>
            <Text>
              {account.state} {account.zipCode}
            </Text>
            <Text>{account.country}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text>{company?.TITLE}</Text>
            <Text>
              {contact.NAME} {contact.LAST_NAME}
            </Text>
            <Text>{contact.UF_ADDRESS}</Text>
          </View>
          <View style={styles.sectionRight}>
            <Text>
              Fecha de creación:{" "}
              {format(new Date(invoice.createdDate), "dd MMM yyyy")}
            </Text>
            <Text>
              Vencimiento: {format(new Date(invoice.dueDate), "dd MMM yyyy")}
            </Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={{ width: 300 }}>Descripción</Text>
            <Text style={{ width: 84, textAlign: "center" }}>Precio</Text>
            <Text style={{ width: 54 }}>Cantidad</Text>
            <Text style={{ width: 84, textAlign: "center" }}>Total</Text>
          </View>
          {invoice.products.map((product) => (
            <View style={styles.tableRow} key={product.id}>
              <Text style={{ width: 300 }}>{product.product}</Text>
              <Text style={{ width: 84, textAlign: "right" }}>
                ${product.price}
              </Text>
              <Text style={{ width: 54, textAlign: "right" }}>
                {product.quantity}
              </Text>
              <Text style={{ width: 84, textAlign: "right" }}>
                ${product.total}
              </Text>
            </View>
          ))}
          <View style={styles.tableFooter}>
            <Text style={{ marginTop: 8 }}>
              Total: $
              {invoice.products.reduce(
                (prev, current) => prev + current.total,
                0
              )}
            </Text>
          </View>
        </View>

        {/* Footer */}
        {invoice.comments && (
          <View style={styles.row}>
            <View style={styles.section}>
              <Text style={{ color: "gray" }}>Comentarios:</Text>
              <Text style={{ marginTop: 4 }}>{invoice.comments || "-"}</Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};
