// @mui
import { Stack, Button, Typography } from "@mui/material";
// components
import Iconify from "../../components/iconify";
import InvoiceProducts from "./InvoiceProducts";
import { InvoiceProduct } from "./InvoiceEditSlider";

// ----------------------------------------------------------------------

type InvoiceFooterProps = {
  products: InvoiceProduct[];
  onProductsChange: (products: InvoiceProduct[]) => void;
  onSubmit: boolean;
};

export default function InvoiceFooter({
  products,
  onProductsChange,
  onSubmit,
}: InvoiceFooterProps) {
  const handleAddProduct = () => {
    onProductsChange([
      ...products,
      { id: "", product: "", quantity: 1, price: 0, total: 0 },
    ]);
  };

  return (
    <Stack>
      <InvoiceProducts products={products} onProductsChange={onProductsChange} />

      <Stack
        spacing={2}
        sx={{ px: 2, py: 2 }}
        direction="row"
        justifyContent="space-between"
      >
        <Button
          size="small"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={handleAddProduct}
          sx={{ flexShrink: 0 }}
        >
          Agregar producto
        </Button>

        <Stack
          justifyContent="flex-end"
          direction="row"
          spacing={2}
          sx={{ py: 1, px: 2.5 }}
        >
          <Stack spacing={4}>
            <Stack direction="row" justifyContent="flex-end">
              <Typography variant="h6">Precio total :</Typography>
              <Typography variant="h6" sx={{ textAlign: "right", width: 120 }}>
                $ {products.reduce((sum, p) => sum + p.total, 0).toFixed(2)}
              </Typography>
            </Stack>

            <Button variant="contained" type="submit" disabled={onSubmit}>
              Guardar
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
