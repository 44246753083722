import { useState, useRef, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
} from "@mui/material";
// components
import Scrollbar from "../../components/scrollbar";
//
import { format } from "date-fns";
import OrderAttachments from "./OrderAttachments";
import OrderViewToolbar from "./OrderViewToolbar";
import { useSharedContext } from "../SharedComponentsContext";
import { getDealProducts } from "../../api/bitrix/deal";
import { DealProduct } from "../../api/bitrix/types/product";
import axiosInstance from "../../utils/axios";
import { dbContact } from "../../api/parnerus/types/contact";
import Iconify from "../../components/iconify";
import { dbCompany } from "../../api/parnerus/types/company";
import { dbInvoice } from "../../api/parnerus/types/invoice";
import { useAuthContext } from "../../auth/useAuthContext";
import OrderProductsRequisites from "./OrderProductsRequisites";
import { InvoiceStages } from "../../api/bitrix/types/invoice";

// ----------------------------------------------------------------------

const StyledLabel = styled("span")(({ theme }) => ({
  ...theme.typography.caption,
  width: 120,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

type Props = {
  isOpen: boolean;
};

export default function OrderDetails({ isOpen }: Props) {
  const { account } = useAuthContext();
  const { closeOrderViewSlider } = useSharedContext();
  const {
    deal,
    openContactViewSlider,
    openCompanyViewSlider,
    openInvoiceViewSlider,
    openOrderPaymentModal,
  } = useSharedContext();
  const [dealProducts, setDealProducts] = useState<DealProduct[]>([]);
  const [contact, setContact] = useState<dbContact>();
  const [company, setCompany] = useState<dbCompany>();
  const [invoice, setInvoice] = useState<dbInvoice>();
  const [orderFiles, setOrderFiles] = useState<string[]>([]);
  const [requisitesModalOpen, setRequisitesModalOpen] =
    useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [liked, setLiked] = useState(false);

  const [completed, setCompleted] = useState(true);

  const handleLiked = () => {
    setLiked(!liked);
  };

  const handleCompleted = () => {
    setCompleted(!completed);
  };

  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (deal && deal.ID) {
      getDealProducts(deal?.ID).then(setDealProducts);

      if (deal.CONTACT_ID) {
        axiosInstance
          .get(
            `/api/contact/list-by-bitrix-id?bitrixId=${deal.CONTACT_ID}&accountId=${account?.accountId}`
          )
          .then((response) => setContact(response.data.results[0]));
      }

      if (deal.COMPANY_ID && deal.COMPANY_ID !== "0") {
        axiosInstance
          .get(
            `/api/company/list-by-bitrix-id?bitrixId=${deal.COMPANY_ID}&accountId=${account?.accountId}`
          )
          .then((response) => setCompany(response.data.results[0]));
      }

      if (deal.UF_CRM_1691018773128) {
        axiosInstance
          .get(`/api/invoice/list-by-id?invoiceId=${deal.UF_CRM_1691018773128}`)
          .then((response) => {
            if (response.data.results.length) {
              setInvoice(response.data.results[0]);
            }
          });
      } else {
        setInvoice(undefined);
      }

      axiosInstance
        .get(`/api/order/list-by-bitrix-id?bitrixId=${deal.ID}`)
        .then((response) => {
          if (response.data.results.length) {
            setOrderFiles(response.data.results[0].filesUploaded);
          } else {
            setOrderFiles([]);
          }
        });
    }
  }, [deal]);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={closeOrderViewSlider}
        anchor="right"
        PaperProps={{
          sx: {
            width: {
              xs: 1,
              sm: 480,
            },
          },
        }}
      >
        <OrderViewToolbar
          taskName={deal?.TITLE || ""}
          fileInputRef={fileInputRef}
          liked={liked}
          completed={completed}
          onLike={handleLiked}
          onAttach={handleClickAttach}
          onCompleted={handleCompleted}
          onCloseDetails={closeOrderViewSlider}
          orderFiles={orderFiles}
          setOrderFiles={setOrderFiles}
        />

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ px: 2.5, pt: 3, pb: 5 }}>
            <Typography variant="overline">Información</Typography>

            <Stack direction="column">
              <StyledLabel sx={{ height: 10, lineHeight: "10px", my: 0.5 }}>
                Contacto
              </StyledLabel>
              <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                onClick={() => openContactViewSlider(contact)}
                sx={{ cursor: "pointer" }}
              >
                {deal?.CONTACT?.NAME} {deal?.CONTACT?.LAST_NAME}
                <Iconify icon="ph:link-light" />
              </Stack>
            </Stack>

            <Stack direction="column">
              <StyledLabel sx={{ height: 10, lineHeight: "10px", my: 0.5 }}>
                Compañía
              </StyledLabel>

              {deal?.COMPANY ? (
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  onClick={() => openCompanyViewSlider(company)}
                  sx={{ cursor: "pointer" }}
                >
                  {deal?.COMPANY?.TITLE}
                  <Iconify icon="ph:link-light" />
                </Stack>
              ) : (
                "-"
              )}
            </Stack>

            <Stack direction="column">
              <StyledLabel sx={{ height: 10, lineHeight: "10px", my: 0.5 }}>
                Factura
              </StyledLabel>

              {invoice ? (
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  onClick={() => openInvoiceViewSlider(invoice)}
                  sx={{ cursor: "pointer", textTransform: "capitalize" }}
                >
                  Factura #{invoice.invoiceId.split("-")[0].toUpperCase()}
                  <Iconify icon="ph:link-light" />
                </Stack>
              ) : (
                <Stack direction="row" flexWrap="wrap" alignItems="center">
                  <Typography variant="body2">
                    No hay una factura relacionada
                  </Typography>
                </Stack>
              )}
            </Stack>

            <Stack direction="column">
              <StyledLabel sx={{ height: 10, lineHeight: "10px", my: 0.5 }}>
                Importe
              </StyledLabel>

              <Stack direction="row" flexWrap="wrap" alignItems="center" mb={1}>
                $ {deal?.OPPORTUNITY}
              </Stack>

              {deal?.INVOICE_STATUS?.STATUS_ID !== InvoiceStages.Paid && (
                <Button
                  variant="contained"
                  sx={{ width: 120 }}
                  onClick={() => openOrderPaymentModal()}
                >
                  Pagar Orden
                </Button>
              )}
            </Stack>

            <Divider />

            <Typography variant="overline">Documentación</Typography>

            <a
              onClick={() => setRequisitesModalOpen(true)}
              style={{
                marginTop: 0,
                marginBottom: -20,
                fontSize: 12,
                fontWeight: 700,
                cursor: "pointer",
                color: "#525252",
              }}
            >
              Ver documentación requerida
              <Iconify icon="gridicons:help" sx={{ color: "#0152FF" }} />
            </a>
            <Stack direction="row">
              {orderFiles && orderFiles.length ? (
                <OrderAttachments attachments={orderFiles} />
              ) : (
                <Typography variant="caption">
                  No hay archivos en esta orden.
                </Typography>
              )}
            </Stack>

            <Divider />

            <Typography variant="overline">DETAILS</Typography>

            <Stack direction="column">
              <StyledLabel sx={{ height: 10, lineHeight: "10px", my: 0.5 }}>
                Productos
              </StyledLabel>

              <Stack direction="row" flexWrap="wrap" alignItems="center">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Descr.</TableCell>
                      <TableCell>Cant.</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dealProducts.map((product) => (
                      <TableRow key={product.PRODUCT_ID}>
                        <TableCell>
                          <Typography variant="overline">
                            {product.PRODUCT_NAME}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            ${product.PRICE}
                            <b>x</b>
                            {product.QUANTITY}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            ${Number(product.PRICE) * Number(product.QUANTITY)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Stack>
            </Stack>

            <Stack direction="column">
              <StyledLabel sx={{ height: 10, lineHeight: "10px", my: 0.5 }}>
                Creado el
              </StyledLabel>

              <Stack direction="row" flexWrap="wrap" alignItems="center">
                {deal && (
                  <Typography variant="caption">
                    {format(new Date(deal.DATE_CREATE), "dd MMM yyyy")}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Stack direction="column">
              <StyledLabel sx={{ height: 10, lineHeight: "10px", my: 0.5 }}>
                Modificado el
              </StyledLabel>

              <Stack direction="row" flexWrap="wrap" alignItems="center">
                {deal && (
                  <Typography variant="caption">
                    {format(new Date(deal.DATE_MODIFY), "dd MMM yyyy")}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Scrollbar>
      </Drawer>
      <OrderProductsRequisites
        open={requisitesModalOpen}
        onClose={() => setRequisitesModalOpen(false)}
        products={dealProducts}
      />
    </>
  );
}
