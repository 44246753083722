import { Helmet } from "react-helmet-async";
import { Button, Container } from "@mui/material";
import Iconify from "../../../components/iconify";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../components/settings";
import { useSharedContext } from "../../../shared/SharedComponentsContext";
import QuotesTable, { QuotesTableVariants } from "./QuoteTable";

export default function QuoteListPage() {
  const { themeStretch } = useSettingsContext();
  const { openQuoteCreateSlider } = useSharedContext();

  return (
    <>
      <Helmet>
        <title> Cotizaciones | Parnerus</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Cotizaciones"
          links={[{ name: "CRM" }, { name: "Cotizaciones" }]}
          action={
            <Button
              // component={RouterLink}
              // to={PATH_DASHBOARD.invoice.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => openQuoteCreateSlider()}
            >
              Nueva Cotización
            </Button>
          }
        />

        <QuotesTable variant={QuotesTableVariants.Default} />
      </Container>
    </>
  );
}
