import { useState } from "react";
// @mui
import {
  Link,
  Stack,
  Button,
  Divider,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
} from "@mui/material";
// utils
import { fDate } from "../../../../utils/formatTime";
import { fCurrency } from "../../../../utils/formatNumber";
// components
import Label from "../../../../components/label";
import Iconify from "../../../../components/iconify";
import { CustomAvatar } from "../../../../components/custom-avatar";
import MenuPopover from "../../../../components/menu-popover";
import ConfirmDialog from "../../../../components/confirm-dialog";
import { dbQuote } from "../../../../api/parnerus/types/quote";
import { format } from "date-fns";
import { useSharedContext } from "../../../../shared/SharedComponentsContext";

// ----------------------------------------------------------------------

type Props = {
  row: dbQuote;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function QuoteTableRow({
  row,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
}: Props) {
  const { openQuoteViewSlider, openQuoteEditSlider } = useSharedContext();
  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover>
        <TableCell align="left" onClick={() => openQuoteViewSlider(row)}>
          {row.quoteId.split("-")[0].toUpperCase()}
        </TableCell>

        <TableCell onClick={() => openQuoteViewSlider(row)}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <CustomAvatar name={row.contactName} />

            <div>
              <Typography variant="subtitle2" textTransform="capitalize" noWrap>
                {row.contactName}
              </Typography>
              <Typography variant="caption" textTransform="capitalize" noWrap>
                {row.companyName}
              </Typography>
            </div>
          </Stack>
        </TableCell>

        <TableCell align="left" onClick={() => openQuoteViewSlider(row)}>
          {fDate(row.createdDate)}
        </TableCell>

        <TableCell align="left" onClick={() => openQuoteViewSlider(row)}>
          {row.dueDate < format(new Date(), "yyyy-MM-dd") &&
          row.status !== "Accepted" &&
          row.status !== "Declined" ? (
              <Label variant="soft" color="error">
                {fDate(row.dueDate)}
              </Label>
            ) : (
              fDate(row.dueDate)
            )}
        </TableCell>

        <TableCell align="center" onClick={() => openQuoteViewSlider(row)}>
          {fCurrency(
            row.products.reduce((prev, current) => prev + current.total, 0)
          )}
        </TableCell>

        <TableCell align="left" onClick={() => openQuoteViewSlider(row)}>
          <Label
            variant="soft"
            color={
              (row.status === "Accepted" && "success") ||
              (row.status === "Sent to customer" && "warning") ||
              (row.status === "Declined" && "error") ||
              "default"
            }
          >
            {row.status}
          </Label>
        </TableCell>

        <TableCell align="right">
          <IconButton
            color={openPopover ? "inherit" : "default"}
            onClick={handleOpenPopover}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            // onViewRow();
            openQuoteViewSlider(row);
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:eye-fill" />
          Detalles
        </MenuItem>

        <MenuItem
          onClick={() => {
            // onEditRow();
            openQuoteEditSlider(row);
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Editar
        </MenuItem>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Eliminar
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Eliminar"
        content="¿Estás seguro que deseas eliminar este registro? Esto deshabilitará el registro, y sólo quedará visible para los registros que estén relacionado al mismo, pero no podrás encontrarlo más en este listado"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Eliminar
          </Button>
        }
      />
    </>
  );
}
