import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "../../../../utils/axios";
import { FormEvent, useEffect, useState } from "react";
import { dbCompany } from "../../../../api/parnerus/types/company";
import { useBitrixContext } from "../../../../api/bitrix/context/BitrixContext";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useSharedContext } from "../../../../shared/SharedComponentsContext";

interface Props extends DialogProps {
  onClose: VoidFunction;
}

const formDefaults: Partial<dbCompany> = {
  TITLE: "",
  INDUSTRY: "",
  UF_STATUS: "",
  UF_IRS_STATUS: "",
  UF_STATE_OF_INCORPORATION: "",
  UF_TYPE_OF_SERVICE: [],
  UF_INCORPORATION_DATE: "",
  WEB: "",
  COMMENTS: "",
};

export default function EditCompanytModal({ onClose, open }: Props) {
  const { company, setCompany, triggerLoading } = useSharedContext();
  const { listFields, userfieldList } = useBitrixContext();
  const [formValues, setFormValues] = useState(formDefaults);
  const [onSubmit, setOnSubmit] = useState(false);

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnSubmit(true);
    const companyUpdated = (await axios.post("/api/company/update", formValues))
      .data.results.Attributes;

    setCompany(companyUpdated);
    setFormValues(formDefaults);
    onClose();
    setOnSubmit(false);
    triggerLoading();
  };

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (company) {
      setFormValues({
        companyId: company.companyId,
        TITLE: company.TITLE || "",
        INDUSTRY: company.INDUSTRY || "",
        UF_STATUS: company.UF_STATUS || "",
        UF_IRS_STATUS: company.UF_IRS_STATUS || "",
        UF_STATE_OF_INCORPORATION: company.UF_STATE_OF_INCORPORATION || "",
        UF_TYPE_OF_SERVICE: company.UF_TYPE_OF_SERVICE || [],
        UF_INCORPORATION_DATE: company.UF_INCORPORATION_DATE || "",
        WEB: company.WEB || "",
        COMMENTS: company.COMMENTS || "",
      });
    }
  }, [company]);

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle> Editar empresa </DialogTitle>

        <form onSubmit={submitForm}>
          <DialogContent sx={{ overflow: "unset" }}>
            <Typography
              variant="overline"
              sx={{ mb: 3, display: "block", color: "text.secondary" }}
            >
              Información general
            </Typography>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                name="TITLE"
                value={formValues.TITLE}
                onChange={handleInputChange}
                fullWidth
                label="Nombre de la Empresa"
                required
              />
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="Sector de actividad"
                fullWidth
                name="INDUSTRY"
                value={formValues.INDUSTRY}
                onChange={handleInputChange}
              >
                {listFields
                  .find((u) => u.FIELD_NAME === "INDUSTRY")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.STATUS_ID} value={value.STATUS_ID}>
                      {value.NAME}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                select
                label="Status"
                fullWidth
                name="UF_STATUS"
                value={formValues.UF_STATUS}
                onChange={handleInputChange}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1683977873")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="IRS Status"
                fullWidth
                name="UF_IRS_STATUS"
                value={formValues.UF_IRS_STATUS}
                onChange={handleInputChange}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1683978024")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                select
                label="State of Incorporation"
                fullWidth
                name="UF_STATE_OF_INCORPORATION"
                value={formValues.UF_STATE_OF_INCORPORATION}
                onChange={handleInputChange}
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1683982286")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>

            <Typography
              variant="overline"
              sx={{ mb: 3, display: "block", color: "text.secondary" }}
            >
              Detalles
            </Typography>

            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                select
                label="Type of service"
                fullWidth
                name="UF_TYPE_OF_SERVICE"
                value={formValues.UF_TYPE_OF_SERVICE}
                onChange={(event) =>
                  handleInputChange({
                    ...event,
                    target: {
                      ...event.target,
                      value: [event.target.value],
                    },
                  })
                }
              >
                {userfieldList
                  .find((u) => u.FIELD_NAME === "UF_CRM_1684078719")
                  ?.LIST.map((value) => (
                    <MenuItem key={value.ID} value={value.ID}>
                      {value.VALUE}
                    </MenuItem>
                  ))}
              </TextField>
              <DesktopDatePicker
                label="Incorporation date"
                value={
                  formValues.UF_INCORPORATION_DATE
                    ? new Date(formValues.UF_INCORPORATION_DATE)
                    : null
                }
                onChange={(newDate) => {
                  if (
                    newDate instanceof Date &&
                    !isNaN(newDate as unknown as number)
                  ) {
                    setFormValues({
                      ...formValues,
                      UF_INCORPORATION_DATE: newDate.toISOString(),
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} margin="normal" />
                )}
              />
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                name="WEB"
                value={formValues.WEB}
                onChange={handleInputChange}
                fullWidth
                label="Sitio web"
              />
            </Stack>
            <Stack
              spacing={3}
              marginBottom={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <TextField
                fullWidth
                label="Comentario"
                multiline
                rows={4}
                name="COMMENTS"
                value={formValues.COMMENTS}
                onChange={handleInputChange}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              color="inherit"
              variant="outlined"
              onClick={onClose}
              disabled={onSubmit}
            >
              Cancelar
            </Button>

            <Button variant="contained" type="submit" disabled={onSubmit}>
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
