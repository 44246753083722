import axios from "./helpers/axios";
import { Status } from "./types/status";
import { sleep } from "./helpers/timer";

export const getStatusValues = (entityId: string): Promise<Status[]> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.status.list?FILTER[ENTITY_ID]=${entityId}`
      )
      .then((result) => {
        sleep(500).then(() => resolve(result.data.result));
      })
      .catch((error) =>
        reject({
          message: `There was an error finding the statuses: ${
            error.response?.data?.error_description || error.message
          }`,
        })
      );
  });
};

export const calculateDealStageProgress = async (
  statusId: string,
  statusList: Status[]
): Promise<number> => {
  const statusPos = statusList.findIndex((s) => s.STATUS_ID === statusId);
  const calculate = Math.round(
    ((statusPos + 1) * 100) / (statusList.length - 1)
  );
  return calculate > 100 ? 100 : calculate;
};
