import { useEffect, useState } from "react";
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
// utils
import { fToNow } from "../../../utils/formatTime";
// _mock_
import { _notifications } from "../../../_mock/arrays";
// components
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import MenuPopover from "../../../components/menu-popover";
import { IconButtonAnimate } from "../../../components/animate";
// Notifications
import { API, Amplify, graphqlOperation } from "aws-amplify";
import { onCreateNotification } from "../../../graphql/subscriptions";
import awsconfig from "../../../aws-exports";
import { notificationsByStaticTypeAndCreatedAt } from "../../../graphql/queries";
import { useAuthContext } from "../../../auth/useAuthContext";
import {
  NotificationsByStaticTypeAndCreatedAtQuery,
  OnCreateNotificationSubscription,
} from "../../../API";
import { updateNotification } from "../../../graphql/mutations";
import { useSharedContext } from "../../../shared/SharedComponentsContext";
import { getDeal } from "../../../api/bitrix/deal";
import { Status } from "../../../api/bitrix/types/status";
import { useBitrixContext } from "../../../api/bitrix/context/BitrixContext";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { user, account } = useAuthContext();
  const { listFields } = useBitrixContext();
  const { openOrderViewSlider } = useSharedContext();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const [notifications, setNotifications] = useState<NotificationItemProps[]>(
    []
  );
  const [notificationsLimit, setNotificationsLimit] = useState(10);

  const totalUnRead = notifications.filter(
    (item) => item.isUnRead === true
  ).length;

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const handleMarkAsRead = (notificationId: string) => {
    setNotifications(
      notifications.map((item) => {
        if (item.id === notificationId) {
          return {
            ...item,
            isUnRead: false,
          };
        }
        return item;
      })
    );
  };

  const handleOpenOrder = async (dealId: string) => {
    setOpenPopover(null);
    openOrderViewSlider(
      await getDeal(
        dealId,
        listFields.find(
          (f: { FIELD_NAME: string }) => f.FIELD_NAME === "DEAL_STAGE"
        )?.LIST as Status[],
        listFields.find(
          (f: { FIELD_NAME: string }) =>
            f.FIELD_NAME === "SMART_INVOICE_STAGE_7"
        )?.LIST as Status[]
      )
    );
  };

  Amplify.configure(awsconfig);

  useEffect(() => {
    // Subscribe to the SNS topic for notifications
    const subscription = (
      API.graphql(
        graphqlOperation(onCreateNotification, {
          filter: {
            userId: { eq: user?.id },
            accountId: { eq: account?.accountId },
          },
        })
      ) as any
    ).subscribe({
      next: (notificationData: {
        value: { data: OnCreateNotificationSubscription };
      }) => {
        // Handle the incoming notification data here
        console.log("Received Notification:", notificationData);
        if (notificationData.value.data.onCreateNotification) {
          setNotifications([
            {
              id: notificationData.value.data.onCreateNotification?.id || "",
              title:
                notificationData.value.data.onCreateNotification?.title || "",
              description:
                notificationData.value.data.onCreateNotification?.message || "",
              avatar: null,
              type: "mail",
              createdAt: new Date(
                notificationData.value.data.onCreateNotification?.createdAt ||
                  ""
              ),
              isUnRead: !notificationData.value.data.onCreateNotification?.read,
              orderId:
                notificationData.value.data.onCreateNotification?.orderId ||
                undefined,
            },
            ...notifications,
          ]);
        }
      },
      error: (error: object) => {
        console.error("Error in subscription:", error);
      },
    });

    // Cleanup the subscription when component unmounts
    return () => {
      subscription.unsubscribe();
    };
  }, [notifications]);

  useEffect(() => {
    (
      API.graphql(
        graphqlOperation(notificationsByStaticTypeAndCreatedAt, {
          staticType: "notification",
          filter: {
            userId: { eq: user?.id },
            accountId: { eq: account?.accountId },
          },
          sortDirection: "DESC",
        })
      ) as Promise<{ data: NotificationsByStaticTypeAndCreatedAtQuery }>
    ).then((response) => {
      if (response.data.notificationsByStaticTypeAndCreatedAt) {
        setNotifications(
          response.data.notificationsByStaticTypeAndCreatedAt.items.map(
            (item) => ({
              id: item?.id || "",
              title: item?.title || "",
              description: item?.message || "",
              avatar: null,
              type: "mail",
              createdAt: new Date(item?.createdAt || ""),
              isUnRead: !item?.read,
              orderId: item?.orderId || undefined,
            })
          )
        );
      }
    });
  }, [notificationsLimit]);

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? "primary" : "default"}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge
          badgeContent={totalUnRead > 10 ? "+10" : totalUnRead}
          color="error"
        >
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 360, p: 0 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificaciones</Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Tienes {totalUnRead} mensajes sin leer
            </Typography>
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Marcar todo como leído">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto", md: 500 } }}>
          <List
            disablePadding
            // subheader={
            //   <ListSubheader
            //     disableSticky
            //     sx={{ py: 1, px: 2.5, typography: "overline" }}
            //   >
            //     Nuevo
            //   </ListSubheader>
            // }
          >
            {notifications.slice(0, notificationsLimit).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleMarkAsRead={handleMarkAsRead}
                handleOpenOrder={handleOpenOrder}
              />
            ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Anterior
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleMarkAsRead={handleMarkAsRead}
              />
            ))}
          </List> */}
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            disabled={notificationsLimit > 10}
            onClick={() => setNotificationsLimit(100)}
          >
            Ver todo
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

type NotificationItemProps = {
  id: string;
  title: string;
  description: string;
  avatar: string | null;
  type: string;
  createdAt: Date;
  isUnRead: boolean;
  orderId?: string;
};

function NotificationItem({
  notification,
  handleMarkAsRead,
  handleOpenOrder,
}: {
  notification: NotificationItemProps;
  handleMarkAsRead: (notificationId: string) => void;
  handleOpenOrder: (dealId: string) => void;
}) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
      onClick={() => {
        API.graphql(
          graphqlOperation(updateNotification, {
            input: {
              id: notification.id,
              read: true,
            },
          })
        );
        handleMarkAsRead(notification.id);

        if (notification.orderId && notification.orderId !== "undefined") {
          handleOpenOrder(notification.orderId);
        }
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack
            direction="row"
            sx={{ mt: 0.5, typography: "caption", color: "text.disabled" }}
          >
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">
              {fToNow(notification.createdAt)}
            </Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: NotificationItemProps) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {notification.description}
      </Typography>
      {notification.orderId && notification.orderId !== "undefined" && (
        <>
          <br />
          <Typography variant="caption">
            &nbsp; Has click para ver la orden
          </Typography>
        </>
      )}
    </Typography>
  );

  if (notification.type === "order_placed") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/notification/ic_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "order_shipped") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/notification/ic_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "mail") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/notification/ic_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "chat_message") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/notification/ic_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}
