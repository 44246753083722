import axios from "./helpers/axios";
import { Product } from "./types/product";

export const getAllProducts = (): Promise<Product[]> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.product.list?SELECT[0]=*&SELECT[1]=PROPERTY_103`
      )
      .then((result) => {
        resolve(result.data.result);
      })
      .catch((error) =>
        reject({
          message: `There was an error finding the products: ${
            error.response?.data?.error_description || error.message
          }`,
        })
      );
  });
};
