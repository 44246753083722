import axiosInstance from "../../../utils/axios";

function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const sendDocument = async (
  documentBlob: Blob,
  to: string,
  subject: string,
  text: string,
  accountId: string
) => {
  const base64Document = await blobToBase64(documentBlob);
  axiosInstance.post("/api/document/send", {
    to,
    subject,
    text,
    file: base64Document,
    accountId,
  });
};
