// @mui
import {
  Stack,
  InputAdornment,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
// components
import Iconify from "../../../components/iconify";
import { OrdersTableVariants } from ".";
import { ListField } from "../../../api/bitrix/types/status";

// ----------------------------------------------------------------------

type Props = {
  filterGeneral: string;
  filterInvoiceStatus?: string;
  isFiltered: boolean;
  optionsInvoiceStatus?: ListField;
  onResetFilter: VoidFunction;
  onFilterGeneral: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterInvoiceStatus: (event: React.ChangeEvent<HTMLInputElement>) => void;
  variant: OrdersTableVariants;
};

export default function OrdersTableToolbar({
  isFiltered,
  filterGeneral,
  filterInvoiceStatus,
  optionsInvoiceStatus,
  onFilterGeneral,
  onFilterInvoiceStatus,
  onResetFilter,
  variant,
}: Props) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: "column",
        sm: "row",
      }}
      sx={{ px: 2.5, py: variant === OrdersTableVariants.Default ? 3 : 0 }}
    >
      {filterInvoiceStatus && (
        <TextField
          size={variant === OrdersTableVariants.Default ? "medium" : "small"}
          fullWidth
          select
          label="Invoice Status"
          value={filterInvoiceStatus}
          onChange={onFilterInvoiceStatus}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 260,
                },
              },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: "capitalize",
          }}
        >
          {optionsInvoiceStatus?.LIST.map((option) => (
            <MenuItem
              key={option.STATUS_ID}
              value={option.STATUS_ID}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: "body2",
                textTransform: "capitalize",
              }}
            >
              {option.NAME}
            </MenuItem>
          ))}
        </TextField>
      )}

      <TextField
        size={variant === OrdersTableVariants.Default ? "medium" : "small"}
        fullWidth
        value={filterGeneral}
        onChange={onFilterGeneral}
        placeholder="Buscar..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Limpiar
        </Button>
      )}
    </Stack>
  );
}
