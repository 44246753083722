import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axiosInstance from "../../utils/axios";
import { useSharedContext } from "../SharedComponentsContext";
import { useBitrixContext } from "../../api/bitrix/context/BitrixContext";
import {
  getDealProducts,
  updateDealInvoiceStatus,
} from "../../api/bitrix/deal";
import { DealProduct } from "../../api/bitrix/types/product";
import { InvoiceStages } from "../../api/bitrix/types/invoice";

interface Props extends DialogProps {
  onClose: VoidFunction;
}

export default function PaymentModal({ onClose, open }: Props) {
  const { deal, triggerLoading, setDeal } = useSharedContext();
  const { productsList, listFields } = useBitrixContext();
  const [dealProducts, setDealProducts] = useState<DealProduct[]>();

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
  );
  const [clientSecret, setClientSecret] = useState<string>("");

  const handleUpdateOrderStatus = async () => {
    if (deal) {
      await updateDealInvoiceStatus(deal.ID).then(() => {
        setDeal({
          ...deal,
          INVOICE_STATUS: listFields
            .find((lf) => lf.FIELD_NAME === "SMART_INVOICE_STAGE_7")
            ?.LIST.find((l) => l.STATUS_ID === InvoiceStages.Paid),
        });
        triggerLoading();
        onClose();
      });
    }
  };

  useEffect(() => {
    if (dealProducts) {
      axiosInstance
        .post("/api/payment/create-checkout-session-order", {
          dealProducts,
          bitrixProducts: productsList,
        })
        .then((response) => setClientSecret(response.data.clientSecret));
    }
  }, [dealProducts]);

  useEffect(() => {
    if (deal && deal.ID) {
      getDealProducts(deal?.ID).then(setDealProducts);
    } else {
      setClientSecret("");
    }
  }, [deal]);

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle textAlign="center"> Pago de la Orden </DialogTitle>
        <DialogContent sx={{ overflow: "unset" }}>
          <Stack
            spacing={3}
            marginBottom={3}
            direction={{ xs: "column", sm: "row" }}
            justifyContent="center"
          >
            <div id="checkout" style={{ width: "100%" }}>
              {clientSecret && (
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{
                    clientSecret,
                    onComplete: () => handleUpdateOrderStatus(),
                  }}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              )}
            </div>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
