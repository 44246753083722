import { useEffect, useState } from "react";
// @mui
import {
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
// components
import Label from "../../../components/label";
import Iconify from "../../../components/iconify";
import MenuPopover from "../../../components/menu-popover";
import ConfirmDialog from "../../../components/confirm-dialog";
import { Deal } from "../../../api/bitrix/types/deal";
import { format } from "date-fns";
import { calculateDealStageProgress } from "../../../api/bitrix/status";
import { useSharedContext } from "../../../shared/SharedComponentsContext";
import { useBitrixContext } from "../../../api/bitrix/context/BitrixContext";
import { Status } from "../../../api/bitrix/types/status";

// ----------------------------------------------------------------------

type Props = {
  row: Deal;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function OrdersTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { 
    ID,
    STAGE,
    TYPE,
    CONTACT,
    COMPANY,
    OPPORTUNITY,
    DATE_MODIFY,
    INVOICE_STATUS
  } = row;

  const {openOrderViewSlider} = useSharedContext();
  const {listFields} = useBitrixContext();

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const [stageProgress, setStageProgress] = useState<number>(0);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  useEffect(() => {
    if (STAGE) {
      calculateDealStageProgress(STAGE.STATUS_ID, listFields.find(f => f.FIELD_NAME === "DEAL_STAGE")?.LIST as Status[])
        .then(setStageProgress);
    }
  }, []);

  return (
    <>
      <TableRow hover selected={selected}>

        <TableCell onClick={() => openOrderViewSlider(row)}>
          <Stack direction="column" alignItems="left">
            <Typography variant="subtitle2" noWrap>
              {`${CONTACT?.NAME} ${CONTACT?.LAST_NAME}`}
            </Typography>
            <Typography variant="caption" noWrap>
              {COMPANY?.TITLE}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="right" onClick={() => openOrderViewSlider(row)}>
          <Typography variant="overline">
            $ {OPPORTUNITY}
          </Typography>
        </TableCell>

        <TableCell align="left" onClick={() => openOrderViewSlider(row)}>
          {STAGE &&
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center">
                <Typography variant="caption" sx={{ flexGrow: 1 }}>
                  {STAGE.NAME}
                </Typography>
              </Stack>

              <LinearProgress
                variant="determinate"
                value={stageProgress}
                color="primary"
              />
            </Stack>
          }
        </TableCell>

        <TableCell align="left" onClick={() => openOrderViewSlider(row)}>
          {INVOICE_STATUS ? 
            <Label
              variant="soft"
              color={INVOICE_STATUS.NAME === "Paid" ? "success" : INVOICE_STATUS.NAME === "Do not pay" ? "error" : INVOICE_STATUS.NAME === "Past Due" ? "warning" : "primary"}
              sx={{ textTransform: "capitalize" }}
            >
              {INVOICE_STATUS.NAME}
            </Label>
            :
            "-"
          }
        </TableCell>

        <TableCell align="left" onClick={() => openOrderViewSlider(row)}>
          <Typography variant="caption">
            {format(new Date(DATE_MODIFY), "yyyy-MM-dd HH:mm")}
          </Typography>
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? "inherit" : "default"} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 220 }}
      >
        <MenuItem
          onClick={() => {
            handleClosePopover();
            openOrderViewSlider(row);
          }}
        >
          <Iconify icon="clarity:details-line" />
          Detalles
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:attach-2-fill" />
          Enviar documentación
        </MenuItem>

        {/* <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Eliminar
        </MenuItem> */}

      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Eliminar
          </Button>
        }
      />
    </>
  );
}
