import { useState, ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
// components
import LoadingScreen from "../components/loading-screen";
//
import Login from "../pages/auth/LoginPage";
import AccountSelect from "../pages/auth/AccountSelectPage";
import { useAuthContext } from "./useAuthContext";
import VerifyCodePage from "../pages/auth/VerifyCodePage";
import axios from "../utils/axios";

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const {
    isAuthenticated,
    isVerifiedUser,
    isAccountAuthenticated,
    isInitialized,
    user,
    loginAccount,
  } = useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (user) {
      if (
        user.accounts &&
        user.accounts.length === 1 &&
        !user.isAccountAuthenticated
      ) {
        axios
          .get(`/api/account/get-account?accountId=${user.accounts[0]}`)
          .then((response) => loginAccount(response.data.account));
      }
    }
  }, [user]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (!isVerifiedUser) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <VerifyCodePage />;
  }

  if (!isAccountAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <AccountSelect />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
