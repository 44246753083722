import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 20,
  retryDelay: (retryCount) => {
    return retryCount * 10000;
  },
  shouldResetTimeout: true,
  onRetry: (retryCount) => {
    console.log("retryCount", retryCount);
  },
  retryCondition: () => true,
});

export default axios;
