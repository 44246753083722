import { useEffect, useState } from "react";
// @mui
import {
  Stack,
  Button,
  Tooltip,
  IconButton,
  Typography,
  LinearProgress,
  MenuItem,
} from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Iconify from "../../components/iconify";
import ConfirmDialog from "../../components/confirm-dialog";
import { useSharedContext } from "../SharedComponentsContext";
import Label from "../../components/label";
import { calculateDealStageProgress } from "../../api/bitrix/status";
import { useBitrixContext } from "../../api/bitrix/context/BitrixContext";
import { Status } from "../../api/bitrix/types/status";
import MenuPopover from "../../components/menu-popover";
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

type Props = {
  fileInputRef: React.RefObject<HTMLInputElement>;
  liked: boolean;
  completed: boolean;
  onLike: VoidFunction;
  onAttach: VoidFunction;
  onCompleted: VoidFunction;
  onCloseDetails: VoidFunction;
};

export default function OrderDetailsToolbar({
  fileInputRef,
  liked,
  completed,
  onLike,
  onAttach,
  onCompleted,
  onCloseDetails,
}: Props) {
  const {
    company,
    openCompanyEditSlider,
    triggerLoading,
    closeCompanyViewSlider,
  } = useSharedContext();
  const { userfieldList } = useBitrixContext();
  const isDesktop = useResponsive("up", "sm");

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleDelete = async (id: string | undefined) => {
    await axios.post("/api/company/disable", {
      companyId: id,
    });
    triggerLoading();
    closeCompanyViewSlider();
  };

  const status = userfieldList
    .find((u) => u.FIELD_NAME === "UF_CRM_1683977873")
    ?.LIST.find((v) => v.ID === company?.UF_STATUS)?.VALUE;

  return (
    <>
      <Stack p={2.5} direction="row" alignItems="center">
        {!isDesktop && (
          <Tooltip title="Back">
            <IconButton onClick={onCloseDetails} sx={{ mr: 1 }}>
              <Iconify icon="eva:arrow-ios-back-fill" />
            </IconButton>
          </Tooltip>
        )}

        <Typography variant="h4" sx={{ pr: 2 }}>
          {company?.TITLE}
        </Typography>

        {isDesktop && status && (
          <Label
            variant="soft"
            color={(company?.UF_STATUS === "67" && "error") || "success"}
            sx={{ textTransform: "capitalize" }}
          >
            {status}
          </Label>
        )}

        {isDesktop && !company?.enabledItem && (
          <Label
            variant="soft"
            color="error"
            sx={{ textTransform: "capitalize", ml: 2 }}
          >
            Deshabilitada
          </Label>
        )}

        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          flexGrow={1}
        >
          <IconButton
            color={openPopover ? "inherit" : "default"}
            onClick={handleOpenPopover}
            size="small"
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>

          <MenuPopover
            open={openPopover}
            onClose={handleClosePopover}
            arrow="right-top"
            sx={{ width: 140 }}
          >
            <MenuItem
              onClick={() => {
                handleClosePopover();
                openCompanyEditSlider(company);
              }}
            >
              <Iconify icon="eva:edit-fill" />
              Editar
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleOpenConfirm();
                handleClosePopover();
              }}
              sx={{ color: "error.main" }}
            >
              <Iconify icon="eva:trash-2-outline" />
              Eliminar
            </MenuItem>
          </MenuPopover>
        </Stack>
      </Stack>

      <input ref={fileInputRef} type="file" style={{ display: "none" }} />

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Eliminar"
        content="¿Estás seguro que deseas eliminar este registro? Esto deshabilitará el registro, y sólo quedará visible para los registros que estén relacionado al mismo, pero no podrás encontrarlo más en este listado"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(company?.companyId)}
          >
            Eliminar
          </Button>
        }
      />
    </>
  );
}
