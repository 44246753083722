// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, "/home"),
  desk: path(ROOTS_DASHBOARD, "/desk"),
  crm: {
    root: path(ROOTS_DASHBOARD, "/crm"),
    deals: path(ROOTS_DASHBOARD, "/crm/deals"),
    clients: path(ROOTS_DASHBOARD, "/crm/clients"),
    companies: path(ROOTS_DASHBOARD, "/crm/companies"),
    invoices: path(ROOTS_DASHBOARD, "/crm/invoices"),
    quotes: path(ROOTS_DASHBOARD, "/crm/quotes"),
  },
  info: path(ROOTS_DASHBOARD, "/info"),
  settings: path(ROOTS_DASHBOARD, "/settings"),
  technicalSupport: path(ROOTS_DASHBOARD, "/technical-support"),
  accountingSupport: path(ROOTS_DASHBOARD, "/accounting-support"),
};
