import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { useSnackbar } from "../../components/snackbar";
import FormProvider, { RHFCodes } from "../../components/hook-form";
import axiosInstance from "../../utils/axios";
import { useAuthContext } from "../../auth/useAuthContext";

// ----------------------------------------------------------------------

type FormValuesProps = {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
};

export default function AuthVerifyCodeForm() {
  const { initialize } = useAuthContext();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required("El código completo es requerido"),
    code2: Yup.string().required("El código completo es requerido"),
    code3: Yup.string().required("El código completo es requerido"),
    code4: Yup.string().required("El código completo es requerido"),
    code5: Yup.string().required("El código completo es requerido"),
    code6: Yup.string().required("El código completo es requerido"),
  });

  const defaultValues = {
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      console.log("DATA", Object.values(data).join(""));
      await axiosInstance.post("/api/account/verify", {
        verifyCode: Object.values(data).join(""),
      });
      enqueueSnackbar("Verificación completada.");
      initialize();
      navigate("/dashboard/home", { replace: true });
    } catch (error) {
      console.error(error);

      reset();

      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFCodes
          keyName="code"
          inputs={["code1", "code2", "code3", "code4", "code5", "code6"]}
        />

        {(!!errors.code1 ||
          !!errors.code2 ||
          !!errors.code3 ||
          !!errors.code4 ||
          !!errors.code5 ||
          !!errors.code6) && (
          <FormHelperText error sx={{ px: 2 }}>
            El código es requerido
          </FormHelperText>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 3 }}
        >
          Verificar
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
