// @mui
import { Stack } from "@mui/material";
// components
import { MultiFilePreview } from "../../components/upload";

// ----------------------------------------------------------------------

type Props = {
  attachments: string[];
};

export default function OrderAttachments({ attachments }: Props) {
  return (
    <Stack direction="row" flexWrap="wrap">
      <MultiFilePreview
        thumbnail
        files={attachments}
        sx={{ width: 64, height: 64 }}
      />
    </Stack>
  );
}
