import { format } from "date-fns";
import { InvoiceProduct } from "../../../shared/InvoiceCreateSlider/InvoiceCreateSlider";
import axiosInstance from "../../../utils/axios";
import { dbCompany } from "../types/company";
import { dbContact } from "../types/contact";

export const createInvoice = async (
  contact: Partial<dbContact>,
  company: Partial<dbCompany> | undefined,
  products: InvoiceProduct[],
  status: string,
  dueDate: string,
  accountId: string,
  quoteId?: string
) => {
  const response = await axiosInstance.post("/api/invoice/insert", {
    contactId: contact.contactId,
    contactName: contact.nameSearch,
    companyId: company?.companyId || null,
    companyName: company?.titleSearch || null,
    status,
    products,
    dueDate,
    createdDate: format(new Date(), "yyyy-MM-dd"),
    quoteId,
    accountId,
  });
  return response.data.invoice;
};

export const updateInvoice = async (
  invoiceId: string,
  contact: Partial<dbContact>,
  company: Partial<dbCompany> | undefined,
  products: InvoiceProduct[],
  status: string,
  dueDate: string,
  comments: string
) => {
  const response = await axiosInstance.post("/api/invoice/update", {
    invoiceId,
    contactId: contact.contactId,
    contactName: contact.nameSearch,
    companyId: company?.companyId || null,
    companyName: company?.titleSearch || null,
    status,
    products,
    dueDate,
    comments,
  });
  return response.data.results.Attributes;
};
