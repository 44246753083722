import { Helmet } from "react-helmet-async";
// sections
import SelectAccount from "../../sections/auth/SelectAccount";

// ----------------------------------------------------------------------

export default function AccountPage() {
  return (
    <>
      <Helmet>
        <title> Seleccionar cuenta | Parnerus</title>
      </Helmet>

      <SelectAccount />
    </>
  );
}
