// @mui
import { Stack, Typography, Link, Button } from "@mui/material";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// layouts
import LoginLayout from "../../layouts/login";
import Iconify from "../../components/iconify";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import { dbAccount } from "../../api/parnerus/types/account";

// ----------------------------------------------------------------------

export default function Login() {
  const { logout, loginAccount } = useAuthContext();
  const [accounts, setAccounts] = useState<dbAccount[]>([]);

  useEffect(() => {
    axios
      .get("/api/account/get-accounts")
      .then((response) => setAccounts(response.data.results));
  }, []);

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
        <Typography variant="h4">Seleccionar cuenta</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">Ingresar como otro usuario</Typography>

          <Link
            onClick={() => logout()}
            variant="subtitle2"
            sx={{ cursor: "pointer" }}
          >
            Inicio de sesión
          </Link>
        </Stack>

        {accounts.map((account) => (
          <Stack direction="row" spacing={2} key={account.accountId}>
            <Typography variant="h5">{account.name}</Typography>
            <Button
              variant="soft"
              size="small"
              color="primary"
              onClick={() => loginAccount(account)}
            >
              Ingresar
              <Iconify icon="material-symbols:login" width={16} />
            </Button>
          </Stack>
        ))}
      </Stack>
    </LoginLayout>
  );
}
