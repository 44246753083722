import { Helmet } from "react-helmet-async";
// @mui
import { Container, Typography } from "@mui/material";
// components
import { useSettingsContext } from "../../components/settings";
import { useEffect } from "react";
import { useAuthContext } from "../../auth/useAuthContext";
import UpgradeCover from "../../components/upgrade-cover/UpgradeCover";

// ----------------------------------------------------------------------

export default function AccountingSupportPage() {
  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();

  useEffect(() => {
    const bitrixFormDiv = document.getElementById("bitrix-form");
    if (bitrixFormDiv && !bitrixFormDiv.innerText) {
      const script = document.createElement("script");
      script.setAttribute("data-b24-form", "inline/9/vuolbc");
      script.setAttribute("data-skip-movin", "true");
      script.textContent = `(function(w,d,u){
        var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
        var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
        })(window,document,'https://cdn.bitrix24.com/b25128493/crm/form/loader_9.js');`;

      bitrixFormDiv.appendChild(script);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Soporte Contable | Parnerus</title>
      </Helmet>

      <Container
        maxWidth={themeStretch ? false : "xl"}
        sx={{ position: "relative" }}
      >
        {/* {(!user?.subscription?.plan ||
          user?.subscription?.plan !== "Parnerus") && (
          <UpgradeCover
            upgradeDescription={`El servicio contable está disponible en el plan Parnerus. Si quieres continuar con la versión ${user?.subscription?.plan} puedes seguir disfrutando nuestras otras herramientas.`}
          />
        )} */}
        <Typography variant="h3" component="h1" paragraph>
          Soporte Contable
        </Typography>

        <Typography gutterBottom>
          Complete el siguiente formulario para recibir soporte contable.
        </Typography>

        <div
          id="bitrix-form"
          style={{ padding: "24px", marginTop: "32px" }}
        ></div>
      </Container>
    </>
  );
}
