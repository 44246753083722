import { Link as RouterLink } from "react-router-dom";
// @mui
import { Stack, Typography, Link, Box } from "@mui/material";
// layouts
import LoginLayout from "../../layouts/login";
// routes
import { PATH_AUTH } from "../../routes/paths";
//
import AuthWithSocial from "./AuthWithSocial";
import AuthRegisterForm from "./AuthRegisterForm";
import {
  StyledRegisterSection,
  StyledRegisterSectionBg,
  StyledRegisterRoot,
  StyledRegisterContent,
} from "../../layouts/login/styles";
import Image from "../../components/image";
import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------

export default function Register() {
  const isMobile = useResponsive("down", "sm");
  return (
    <StyledRegisterRoot>
      <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
        <Box
          component="img"
          src="/logo/parnerus-logo-white.png"
          sx={{
            width: 168,
            height: 100,
            cursor: "pointer",
            zIndex: 9,
            position: "absolute",
            mt: { xs: 1.5, md: 5 },
            ml: { xs: 2, md: 5 },
          }}
        />
      </Link>

      <StyledRegisterSection>
        <Stack p={6} pr={10} sx={{ maxWidth: 1200 }}>
          <Typography
            variant="h3"
            sx={{
              mb: 2,
              textTransform: "uppercase",
              color: "white",
              fontWeight: 900,
            }}
          >
            Crea empresas, lleva la contabilidad, declara los impuestos para tus
            clientes
          </Typography>

          <Typography
            variant="body2"
            sx={{
              mb: 2,
              color: "white",
              fontStyle: "italic",
              fontWeight: 700,
            }}
          >
            Ofrece servicios de incorporación: (Creación de empresas, LP, LLC,
            Corp.) renovaciones anuales, agente registrado, enmiendas, reportes
            BOI. Servicios contables: libros diarios, mayor y balance, reporte
            de impuestos a las ventas, administración de nómina, planificación
            fiscal. Servicios impositivos: Aplicación de ITIN, aplicación EIN,
            W2, W8, W9, 1099, anticipos de ganancias, impuestos de personas y
            empresas, 5472. Y mucho más.
          </Typography>

          <Typography
            variant="h3"
            sx={{
              mb: 2,
              color: "white",
            }}
          >
            Tus clientes, son tus clientes.
          </Typography>
        </Stack>

        {/* <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src="/assets/illustrations/parnerus-mock.png"
          sx={{ maxWidth: 1400 }}
        /> */}

        <StyledRegisterSectionBg />
      </StyledRegisterSection>

      <StyledRegisterContent>
        <Stack sx={{ width: 1 }}>
          <Stack
            spacing={1}
            sx={{
              mb: 2,
              position: "relative",
              mt: isMobile ? -7 : -25,
            }}
          >
            <Typography variant="h4">Crea una cuenta gratis!</Typography>
            <Typography variant="body2">
              Accede a nuestro plan{" "}
              <b>
                <i>FREE</i>
              </b>
              mium, sin periodos de prueba y sin agregar tu tarjeta con toda la
              funcionalidad completa:
            </Typography>
          </Stack>

          <Stack
            sx={{ backgroundColor: "#E7F0FE", p: 2 }}
            spacing={1}
            mb={3}
            borderRadius={1}
          >
            <Stack direction="row" spacing={1}>
              <img src="/assets/icons/register-icon.png" height={24} />
              <Typography fontSize={14}>
                Organizador de clientes y empresas
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <img src="/assets/icons/register-icon.png" height={24} />
              <Typography fontSize={14}>
                Generador de cotizaciones y facturas
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <img src="/assets/icons/register-icon.png" height={24} />
              <Typography fontSize={14}>
                Generador de ordenes y servicios
              </Typography>
            </Stack>
          </Stack>

          <AuthRegisterForm />

          <Stack direction="row" spacing={0.5} mt={2}>
            <Typography variant="body2">
              {" "}
              ¿Tienes una cuenta? Ingresa aquí{" "}
            </Typography>

            <Link to="/" component={RouterLink} variant="subtitle2">
              Ingresar
            </Link>
          </Stack>

          <Typography
            component="div"
            sx={{
              color: "text.secondary",
              mt: 1,
              typography: "caption",
              textAlign: "center",
            }}
          >
            {"Registrándose, Aceptas los "}
            <Link underline="always" color="text.primary">
              Términos de servicio
            </Link>
            {" y "}
            <Link underline="always" color="text.primary">
              Políticas de privacidad
            </Link>
            .
          </Typography>

          {/* <AuthWithSocial /> */}
        </Stack>
      </StyledRegisterContent>
    </StyledRegisterRoot>
  );
}
