import { format } from "date-fns";
import { QuoteProduct } from "../../../shared/QuoteCreateSlider/QuoteCreateSlider";
import axiosInstance from "../../../utils/axios";
import { dbCompany } from "../types/company";
import { dbContact } from "../types/contact";

export const createQuote = async (
  contact: dbContact,
  company: dbCompany | undefined,
  products: QuoteProduct[],
  status: string,
  dueDate: string,
  accountId: string
) => {
  const response = await axiosInstance.post("/api/quote/insert", {
    contactId: contact.contactId,
    contactName: contact.nameSearch,
    companyId: company?.companyId || null,
    companyName: company?.titleSearch || null,
    status,
    products,
    dueDate,
    createdDate: format(new Date(), "yyyy-MM-dd"),
    accountId,
  });
  return response.data.quote;
};

export const updateQuote = async (
  quoteId: string,
  contact: dbContact,
  company: dbCompany | undefined,
  products: QuoteProduct[],
  status: string,
  dueDate: string
) => {
  const response = await axiosInstance.post("/api/quote/update", {
    quoteId,
    contactId: contact.contactId,
    contactName: contact.nameSearch,
    companyId: company?.companyId || null,
    companyName: company?.titleSearch || null,
    status,
    products,
    dueDate,
  });
  return response.data.results.Attributes;
};
