/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  useMemo,
  useState,
  useContext,
  useCallback,
  useReducer,
  Dispatch,
  SetStateAction,
} from "react";

import { Deal } from "../api/bitrix/types/deal";
import { dbContact } from "../api/parnerus/types/contact";
import { dbCompany } from "../api/parnerus/types/company";
import { dbInvoice } from "../api/parnerus/types/invoice";
import { dbQuote } from "../api/parnerus/types/quote";
import { ProposedOrder } from "../api/parnerus/types/order";

type SharedContextType = {
  deal: Deal | undefined;
  setDeal: Dispatch<SetStateAction<Deal | undefined>>;
  contact: dbContact | undefined;
  company: dbCompany | undefined;
  invoice: dbInvoice | undefined;
  quote: dbQuote | undefined;
  openOrderCreateSlider: () => void;
  closeOrderCreateSlider: () => void;
  orderCreateSliderOpen: boolean;
  orderViewSliderOpen: boolean;
  openOrderViewSlider: (deal?: Deal) => void;
  closeOrderViewSlider: () => void;
  contactViewSliderOpen: boolean;
  openContactViewSlider: (contact?: dbContact) => void;
  closeContactViewSlider: () => void;
  contactEditSliderOpen: boolean;
  openContactEditSlider: (contact?: dbContact) => void;
  closeContactEditSlider: () => void;
  companyViewSliderOpen: boolean;
  openCompanyViewSlider: (company?: dbCompany) => void;
  closeCompanyViewSlider: () => void;
  companyEditSliderOpen: boolean;
  openCompanyEditSlider: (company?: dbCompany) => void;
  closeCompanyEditSlider: () => void;
  invoiceCreateSliderOpen: boolean;
  invoiceViewSliderOpen: boolean;
  invoiceEditSliderOpen: boolean;
  quoteCreateSliderOpen: boolean;
  quoteViewSliderOpen: boolean;
  quoteEditSliderOpen: boolean;
  openInvoiceCreateSlider: () => void;
  closeInvoiceCreateSlider: () => void;
  openInvoiceViewSlider: (invoice?: dbInvoice) => void;
  closeInvoiceViewSlider: () => void;
  openInvoiceEditSlider: (invoice?: dbInvoice) => void;
  closeInvoiceEditSlider: () => void;
  openQuoteCreateSlider: () => void;
  closeQuoteCreateSlider: () => void;
  openQuoteViewSlider: (quote?: dbQuote) => void;
  closeQuoteViewSlider: () => void;
  openQuoteEditSlider: (quote?: dbQuote) => void;
  closeQuoteEditSlider: () => void;
  // Payment Modal
  openPaymentModal: (order?: ProposedOrder) => void;
  closePaymentModal: () => void;
  paymentModalOpen: boolean;
  openOrderPaymentModal: () => void;
  closeOrderPaymentModal: () => void;
  orderPaymentModalOpen: boolean;
  proposedOrder: ProposedOrder | undefined;
  // Upgrade Modal
  openUpgradeModal: (upgradeDescription: string) => void;
  closeUpgradeModal: () => void;
  upgradeModal: {
    open: boolean;
    upgradeDescription: string;
  };
  // Loading trigget
  loadingTrigger: boolean;
  triggerLoading: React.DispatchWithoutAction;
  // Set objects
  setContact: React.Dispatch<React.SetStateAction<dbContact | undefined>>;
  setCompany: React.Dispatch<React.SetStateAction<dbCompany | undefined>>;
  setInvoice: React.Dispatch<React.SetStateAction<dbInvoice | undefined>>;
  setQuote: React.Dispatch<React.SetStateAction<dbQuote | undefined>>;
};

export const SharedContext = createContext<SharedContextType | null>(null);

// ----------------------------------------------------------------------

type SharedProviderProps = {
  children: React.ReactNode;
};

export function SharedProvider({ children }: SharedProviderProps) {
  //Objects================================
  const [deal, setDeal] = useState<Deal>();
  const [contact, setContact] = useState<dbContact>();
  const [company, setCompany] = useState<dbCompany>();
  const [invoice, setInvoice] = useState<dbInvoice>();
  const [quote, setQuote] = useState<dbQuote>();

  // Sliders statements====================
  const [orderCreateSliderOpen, setOrderCreateSliderOpen] =
    useState<boolean>(false);

  const [orderViewSliderOpen, setOrderViewSliderOpen] =
    useState<boolean>(false);

  const [contactViewSliderOpen, setContactViewSliderOpen] =
    useState<boolean>(false);

  const [contactEditSliderOpen, setContactEditSliderOpen] =
    useState<boolean>(false);

  const [companyViewSliderOpen, setCompanyViewSliderOpen] =
    useState<boolean>(false);

  const [companyEditSliderOpen, setCompanyEditSliderOpen] =
    useState<boolean>(false);

  const [invoiceCreateSliderOpen, setInvoiceCreateSliderOpen] =
    useState<boolean>(false);

  const [invoiceViewSliderOpen, setInvoiceViewSliderOpen] =
    useState<boolean>(false);

  const [invoiceEditSliderOpen, setInvoiceEditSliderOpen] =
    useState<boolean>(false);

  const [quoteCreateSliderOpen, setQuoteCreateSliderOpen] =
    useState<boolean>(false);

  const [quoteViewSliderOpen, setQuoteViewSliderOpen] =
    useState<boolean>(false);

  const [quoteEditSliderOpen, setQuoteEditSliderOpen] =
    useState<boolean>(false);

  // Payment Modal========================
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const [proposedOrder, setProposedOrder] = useState<ProposedOrder>();

  const [orderPaymentModalOpen, setOrderPaymentModalOpen] =
    useState<boolean>(false);

  // Upgrade Modal========================
  const [upgradeModal, setUpgradeModal] = useState({
    open: false,
    upgradeDescription: "",
  });

  // Loading trigger=====================
  const [loadingTrigger, triggerLoading] = useReducer((state) => !state, false);

  // Slider functions=====================
  const openOrderCreateSlider = useCallback(() => {
    setOrderCreateSliderOpen(true);
  }, []);
  const closeOrderCreateSlider = useCallback(() => {
    setOrderCreateSliderOpen(false);
  }, []);

  const openOrderViewSlider = useCallback((deal?: Deal) => {
    setDeal(deal);
    setOrderViewSliderOpen(true);
  }, []);
  const closeOrderViewSlider = useCallback(() => {
    setOrderViewSliderOpen(false);
  }, []);

  //

  const openInvoiceCreateSlider = useCallback(() => {
    setInvoiceCreateSliderOpen(true);
  }, []);
  const closeInvoiceCreateSlider = useCallback(() => {
    setInvoiceCreateSliderOpen(false);
  }, []);

  const openInvoiceViewSlider = useCallback((invoice?: dbInvoice) => {
    setInvoice(invoice);
    setInvoiceViewSliderOpen(true);
  }, []);
  const closeInvoiceViewSlider = useCallback(() => {
    setInvoiceViewSliderOpen(false);
  }, []);

  const openInvoiceEditSlider = useCallback((invoice?: dbInvoice) => {
    setInvoice(invoice);
    setInvoiceEditSliderOpen(true);
  }, []);
  const closeInvoiceEditSlider = useCallback(() => {
    setInvoiceEditSliderOpen(false);
  }, []);

  //

  const openQuoteCreateSlider = useCallback(() => {
    setQuoteCreateSliderOpen(true);
  }, []);
  const closeQuoteCreateSlider = useCallback(() => {
    setQuoteCreateSliderOpen(false);
  }, []);

  const openQuoteViewSlider = useCallback((quote?: dbQuote) => {
    setQuote(quote);
    setQuoteViewSliderOpen(true);
  }, []);
  const closeQuoteViewSlider = useCallback(() => {
    setQuoteViewSliderOpen(false);
  }, []);

  const openQuoteEditSlider = useCallback((quote?: dbQuote) => {
    setQuote(quote);
    setQuoteEditSliderOpen(true);
  }, []);
  const closeQuoteEditSlider = useCallback(() => {
    setQuoteEditSliderOpen(false);
  }, []);

  //

  const openContactViewSlider = useCallback((contact?: dbContact) => {
    setContact(contact);
    setContactViewSliderOpen(true);
  }, []);
  const closeContactViewSlider = useCallback(() => {
    setContactViewSliderOpen(false);
  }, []);

  const openContactEditSlider = useCallback((contact?: dbContact) => {
    setContact(contact);
    setContactEditSliderOpen(true);
  }, []);
  const closeContactEditSlider = useCallback(() => {
    setContactEditSliderOpen(false);
  }, []);

  const openCompanyViewSlider = useCallback((company?: dbCompany) => {
    setCompany(company);
    setCompanyViewSliderOpen(true);
  }, []);
  const closeCompanyViewSlider = useCallback(() => {
    setCompanyViewSliderOpen(false);
  }, []);

  const openCompanyEditSlider = useCallback((company?: dbCompany) => {
    setCompany(company);
    setCompanyEditSliderOpen(true);
  }, []);
  const closeCompanyEditSlider = useCallback(() => {
    setCompanyEditSliderOpen(false);
  }, []);

  // Payment functions
  const openPaymentModal = useCallback((order?: ProposedOrder) => {
    setProposedOrder(order);
    setPaymentModalOpen(true);
  }, []);
  const closePaymentModal = useCallback(() => {
    setProposedOrder(undefined);
    setPaymentModalOpen(false);
  }, []);

  const openOrderPaymentModal = useCallback(() => {
    setOrderPaymentModalOpen(true);
  }, []);
  const closeOrderPaymentModal = useCallback(() => {
    setOrderPaymentModalOpen(false);
  }, []);

  // Upgrade functions
  const openUpgradeModal = useCallback((upgradeDescription: string) => {
    setUpgradeModal({
      open: true,
      upgradeDescription,
    });
  }, []);
  const closeUpgradeModal = useCallback(() => {
    setUpgradeModal({
      open: false,
      upgradeDescription: upgradeModal.upgradeDescription,
    });
  }, []);

  // Context setup======================
  const memoizedValue = useMemo(
    () => ({
      deal,
      setDeal,
      contact,
      company,
      invoice,
      quote,
      openOrderCreateSlider,
      closeOrderCreateSlider,
      orderCreateSliderOpen,
      orderViewSliderOpen,
      openOrderViewSlider,
      closeOrderViewSlider,
      contactViewSliderOpen,
      openContactViewSlider,
      closeContactViewSlider,
      contactEditSliderOpen,
      openContactEditSlider,
      closeContactEditSlider,
      companyViewSliderOpen,
      openCompanyViewSlider,
      closeCompanyViewSlider,
      companyEditSliderOpen,
      openCompanyEditSlider,
      closeCompanyEditSlider,
      invoiceCreateSliderOpen,
      invoiceViewSliderOpen,
      invoiceEditSliderOpen,
      quoteCreateSliderOpen,
      quoteViewSliderOpen,
      quoteEditSliderOpen,
      openInvoiceCreateSlider,
      closeInvoiceCreateSlider,
      openInvoiceViewSlider,
      closeInvoiceViewSlider,
      openInvoiceEditSlider,
      closeInvoiceEditSlider,
      openQuoteCreateSlider,
      closeQuoteCreateSlider,
      openQuoteViewSlider,
      closeQuoteViewSlider,
      openQuoteEditSlider,
      closeQuoteEditSlider,
      // Payment Modal
      openPaymentModal,
      closePaymentModal,
      paymentModalOpen,
      openOrderPaymentModal,
      closeOrderPaymentModal,
      orderPaymentModalOpen,
      proposedOrder,
      openUpgradeModal,
      closeUpgradeModal,
      upgradeModal,
      loadingTrigger,
      triggerLoading,
      setContact,
      setCompany,
      setInvoice,
      setQuote,
    }),
    [
      deal,
      setDeal,
      contact,
      company,
      invoice,
      quote,
      openOrderCreateSlider,
      closeOrderCreateSlider,
      orderCreateSliderOpen,
      orderViewSliderOpen,
      openOrderViewSlider,
      closeOrderViewSlider,
      contactViewSliderOpen,
      openContactViewSlider,
      closeContactViewSlider,
      contactEditSliderOpen,
      openContactEditSlider,
      closeContactEditSlider,
      companyViewSliderOpen,
      openCompanyViewSlider,
      closeCompanyViewSlider,
      companyEditSliderOpen,
      openCompanyEditSlider,
      closeCompanyEditSlider,
      invoiceCreateSliderOpen,
      invoiceViewSliderOpen,
      invoiceEditSliderOpen,
      quoteCreateSliderOpen,
      quoteViewSliderOpen,
      quoteEditSliderOpen,
      openInvoiceCreateSlider,
      closeInvoiceCreateSlider,
      openInvoiceViewSlider,
      closeInvoiceViewSlider,
      openInvoiceEditSlider,
      closeInvoiceEditSlider,
      openQuoteCreateSlider,
      closeQuoteCreateSlider,
      openQuoteViewSlider,
      closeQuoteViewSlider,
      openQuoteEditSlider,
      closeQuoteEditSlider,
      // Payment Modal
      openPaymentModal,
      closePaymentModal,
      paymentModalOpen,
      openOrderPaymentModal,
      closeOrderPaymentModal,
      orderPaymentModalOpen,
      proposedOrder,
      openUpgradeModal,
      closeUpgradeModal,
      upgradeModal,
      loadingTrigger,
      triggerLoading,
      setContact,
      setCompany,
      setInvoice,
      setQuote,
    ]
  );

  return (
    <SharedContext.Provider value={memoizedValue}>
      {children}
    </SharedContext.Provider>
  );
}

export const useSharedContext = () => {
  const context = useContext(SharedContext);

  if (!context)
    throw new Error(
      "useSharedContext context must be use inside SharedProvider"
    );

  return context;
};
