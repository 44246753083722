/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1c7f89f1-61ab-4a5a-9c02-331f47b19fb5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mt9oUkgdU",
    "aws_user_pools_web_client_id": "7ehs7nets871kaa3e8qoflm57v",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "b177cb70dbda44429ebf4830fe2c01cc",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "b177cb70dbda44429ebf4830fe2c01cc",
            "region": "us-east-1"
        }
    },
    "Notifications": {
        "InAppMessaging": {
            "AWSPinpoint": {
                "appId": "b177cb70dbda44429ebf4830fe2c01cc",
                "region": "us-east-1"
            }
        }
    },
    "aws_appsync_graphqlEndpoint": "https://c2irhlgsznbwtl7dft7w2s3jyq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-piset2s26besldm2sbre76a3ka"
};


export default awsmobile;
