/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  useMemo,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { ListField } from "../types/status";
import { getStatusValues } from "../status";
import { Product } from "../types/product";
import { getAllProducts } from "../product";
import localStorageAvailable from "../../../utils/localStorageAvailable";
import { CustomFields } from "../types/userFields";
import { getCustomListFields } from "../userFields";

type BitrixContextType = {
  listFields: ListField[];
  productsList: Product[];
  userfieldList: CustomFields[];
};

export const BitrixContext = createContext<BitrixContextType | null>(null);

// ----------------------------------------------------------------------

type BitrixProviderProps = {
  children: React.ReactNode;
};

export function BitrixProvider({ children }: BitrixProviderProps) {
  const [listFields, setListFields] = useState<ListField[]>([]);
  const [productsList, setProductsList] = useState<Product[]>([]);
  const [userfieldList, setUserFieldList] = useState<CustomFields[]>([]);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    if (storageAvailable) {
      // listFields =========================
      const storedListFields = localStorage.getItem("listFields");
      const storedListFieldsObject =
        storedListFields && JSON.parse(storedListFields);

      if (storedListFieldsObject) {
        setListFields(storedListFieldsObject);
      } else {
        const newListFields = [
          {
            FIELD_NAME: "DEAL_STAGE",
            LIST: await getStatusValues("DEAL_STAGE"),
          },
          {
            FIELD_NAME: "DEAL_TYPE",
            LIST: await getStatusValues("DEAL_TYPE"),
          },
          {
            FIELD_NAME: "SMART_INVOICE_STAGE_7",
            LIST: await getStatusValues("SMART_INVOICE_STAGE_7"),
          },
          {
            FIELD_NAME: "SOURCE",
            LIST: await getStatusValues("SOURCE"),
          },
          {
            FIELD_NAME: "INDUSTRY",
            LIST: await getStatusValues("INDUSTRY"),
          },
        ];

        setListFields(newListFields);
        localStorage.setItem("listFields", JSON.stringify(newListFields));
      }

      // productsList =========================
      const storedProductsList = localStorage.getItem("productsList");
      const storedProductsListObject =
        storedProductsList && JSON.parse(storedProductsList);

      // if (storedProductsListObject) {
      //   setProductsList(storedProductsListObject);
      // } else {
      const newProductsList = await getAllProducts();

      setProductsList(newProductsList);
      localStorage.setItem("productsList", JSON.stringify(newProductsList));
      // }

      // userfieldList =========================
      const storedUserfieldList = localStorage.getItem("userfieldList");
      const storedUserfieldListObject =
        storedUserfieldList && JSON.parse(storedUserfieldList);

      if (storedUserfieldListObject) {
        setUserFieldList(storedUserfieldListObject);
      } else {
        const newUserfieldList = [
          // ...(await getCustomListFields("lead")),
          // ...(await getCustomListFields("deal")),
          ...(await getCustomListFields("contact")),
          ...(await getCustomListFields("company")),
        ];

        setUserFieldList(newUserfieldList);
        localStorage.setItem("userfieldList", JSON.stringify(newUserfieldList));
      }
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const memoizedValue = useMemo(
    () => ({
      listFields,
      productsList,
      userfieldList,
    }),
    [listFields, productsList, userfieldList]
  );

  return (
    <BitrixContext.Provider value={memoizedValue}>
      {children}
    </BitrixContext.Provider>
  );
}

export const useBitrixContext = () => {
  const context = useContext(BitrixContext);

  if (!context)
    throw new Error(
      "useBitrixContext context must be use inside BitrixProvider"
    );

  return context;
};
